import React, { FunctionComponent } from "react";
import styles from "./loader.error.module.scss";
import { ErrorData } from "../../utils/error";
import { HasContainers, WithTranslations } from "../../services/localisation/types";
import { addTranslations } from "../../services/localisation/utils";
import { PageContainer } from "../Atoms/PageContainer";
import { NavBar } from "../NavBar";
import { useIsDesktop } from "../../utils/use-is-desktop";
import { Button } from "../Button/button";

const defaultTranslations = {
  somethingWentWrong: "Something went wrong",
  reload: "Reload this page",
  requestTimeout: "Request Timeout",
  contactVRTranspoint: "If the problems still persist, contact VR Transpoint"
};

const UntranslatedErrorPage: FunctionComponent<
  WithTranslations<
    {
      error: ErrorData;
    },
    typeof defaultTranslations
  > &
    HasContainers
> = ({ translations = defaultTranslations, ...props }) => {
  const errorText = () => {
    if (props.error.statusCode) {
      switch (props.error.statusCode) {
        case 408:
          return translations.requestTimeout;
      }
    }   
  };

  const isDesktop = useIsDesktop();

  function refreshPage(){ 
    window.location.reload(); 
}

  return (
    <>

      <PageContainer className={styles.pageContainer}>     
        <div className={styles.contentContainer}>
          <div className={styles.textContainer}>
            <p className={styles.errorMessage}>
            {props.error.statusCode + ", " + errorText()}
            </p>
            <h1 className={styles.header}>
              <span>
                {translations.somethingWentWrong}
              </span>
            </h1>
          </div>
            <Button className={styles.button} color="green"  onClick={ refreshPage }>
            <p className={styles.buttoText}> {translations.reload}</p>
            </Button>
          <div className={styles.advice}>{translations.contactVRTranspoint}</div>
        </div>
      </PageContainer>
    </>
  );
};

export const ErrorLoaderPage = addTranslations(UntranslatedErrorPage, {
  somethingWentWrong: { id: "error.somethingWentWrong" },
  reload: { id: "action.reloadThisPage" },
  requestTimeout: { id: "error.requestTimeout" },
  contactVRTranspoint: {id: "error.contactVRTranspoint"}
});
