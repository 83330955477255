import { EnvOpts } from ".";

export const config: EnvOpts = {
  useTestingToken: false,
  testingToken: "",
  userPoolAskiWebClientId: "7e76jeg7onn7a3tbnqp935h68u",
  cognito: {
    region: "eu-west-1",
    userPoolId: "eu-west-1_nqlPrADw6",
    userPoolWebClientId: "7pleinsvb3cnfdp5ca5ipa3d9s",
    userIdentityProvider: "asiakas360-devprod-vr-ad-users",
    adminIdentityProvider: "asiakas360-devprod-vr-ad-admins",
    domain: "asiakas360-devprod.auth.eu-west-1.amazoncognito.com"
  },
  api: {
    url: "/api"
  }
};
