import React, {useState, useEffect, FunctionComponent} from "react";
import { addTranslations } from "../../services/localisation/utils";
import {ErrorLoaderPage} from "../Error/loader.error.page";
import { Loader } from "./loader";
import styles from "./loader.module.scss"
import { HasContainers, WithTranslations } from "../../services/localisation/types";

 const defaultTranslations = {
    loadingInformation: "Loading infomation...",
    delay: "Sorry, the content is taking longer to load than expected.",
  };

export const UntranslatedLoaderWithMessage: FunctionComponent<
  WithTranslations< {}, typeof defaultTranslations> & HasContainers
> = ({ translations = defaultTranslations}) => {

  const [firsttMessageShown, setFirstMessageShown] = useState(false);
  const [secondMessageShown, setSecondMessageShown] = useState(false);
  const [thirdMessageShown, setSThirdMessageShown] = useState(false);
  const [loaderShown, setLoaderShown] = useState(false);

  useEffect(() => {
    const loaderTimer = setTimeout(() => {
      setLoaderShown(true);
    }, 0);
    // FIRST TIMEOUT
    const firstTimer = setTimeout(() => {
      setFirstMessageShown(true);
    }, 0);
    // SECOND TIMEOUT 15 SECONDS
   const secondTimer = setTimeout(() => {
      setSecondMessageShown(true);
    }, 15000);
    // THIRD TIMEOUT 30 SECONDS
    const thirdTimer = setTimeout(() => {
      setSThirdMessageShown(true);
      setTimeout(() => {
        setFirstMessageShown(false);
      }, 0);
      setTimeout(() => {
        setSecondMessageShown(false);
      }, 0);
      setTimeout(() => {
        setLoaderShown(false);
      }, 0);
    }, 30000);
    
    return () => {
      clearTimeout(firstTimer);
      clearTimeout(secondTimer);
      clearTimeout(loaderTimer);
    };
  }, []);
  
  return (
    <div className={styles.loaderContainer}>
      {loaderShown &&  <Loader size="large" textColor="dark"> 
        {firsttMessageShown && 
        <div className={styles.loaderMessageOne}>{translations.loadingInformation} </div>
        }
        {secondMessageShown &&  
        <div className={styles.loaderMessageTwo}>{translations.delay} </div>
        } 
        </Loader>
      }
      {thirdMessageShown && <ErrorLoaderPage error={{ statusCode: 408 }}  />}   
    </div>                          
  )                      
};

export const LoaderWithMessage  = addTranslations(UntranslatedLoaderWithMessage, {
  loadingInformation: { id: "loader.loadingInformation" },
  delay: { id: "loader.delay" },
});
