import React, { FunctionComponent, useContext, useEffect } from "react";
import styles from "./navbar.module.scss";
import classnames from "classnames";
import { NavigationStyle } from "../../router/protected-route";
import { BurgerIcon, PersonIcon, StatusMessageIcon } from "../Icons";
import { BackButton } from "../Button/back-button";
import { LinkToHome, InlineLink } from "../Atoms/Link";
import { WithTranslations, HasContainers } from "../../services/localisation/types";
import { addTranslations } from "../../services/localisation/utils";
import { NavigationStateContext } from "../Navigation";
import { useLocation } from "react-router";
import { AppVersion } from "../Atoms/AppVersion";
import { StatusMessageStateContext } from "../Atoms/StatusMessage";
import { PlainButton } from "../Button/button";
import { withAuthentication } from "../../services/authentication/authentication.provider";
import classNames from "classnames";
import { useUserInformation } from "../../utils/use-user-information";
import { OrderingUsersOnly } from "../Controls/UserControl/ordering-users-only";

const defaultNavBarTranslations = {
  orderNumber: "VR order number",
  statusMessageRail: "",
  statusMessageRoad: ""
};

const defaultDesktopNavBarTranslations = {
  search: "Search",
  management: "Management",
  orders: "Orders",
  shunting: "Shunting",
  reports: "reports",
  statusMessageRail: "",
  statusMessageRoad: ""
};

export const UntranslatedNavBar: FunctionComponent<
  WithTranslations<
    {
      className?: string;
      navigationStyle: NavigationStyle;
      orderNumber?: string;
    },
    typeof defaultNavBarTranslations
  >
> = ({ translations = defaultNavBarTranslations, ...props }) => {
  const { open, showStatusMessage } = useContext(StatusMessageStateContext);
  const hasStatusMessage = translations.statusMessageRail !== "undefined" || translations.statusMessageRoad !== "undefined";

  return (
    <div className={styles.navbarContainer}>
      {props.navigationStyle === "MAIN" && (
        <NavigationContainer
          hasStatusMessage={hasStatusMessage}
          isAppVersionVisible
          open={open}
          showStatusMessage={showStatusMessage}
        >
          <div className={styles.logo}>
            <LinkToHome />
          </div>
        </NavigationContainer>
      )}
      {props.navigationStyle === "BACK_BURGER" && (
        <NavigationContainer
          hasStatusMessage={hasStatusMessage}
          isAppVersionVisible={false}
          open={open}
          showStatusMessage={showStatusMessage}
        >
          {<BackButton />}
        </NavigationContainer>
      )}
      {props.navigationStyle === "ORDER" && (
        <NavigationContainer
          hasStatusMessage={hasStatusMessage}
          isAppVersionVisible={false}
          open={open}
          showStatusMessage={showStatusMessage}
        >
          {<BackButton />}
          <p className={styles.orderNumber}>{`${translations.orderNumber}: ${props.orderNumber}`}</p>
        </NavigationContainer>
      )}
    </div>
  );
};

const BurgerNavigationIcon: FunctionComponent = () => {
  const { open, showNavigation, hideNavigation } = useContext(NavigationStateContext);
  const onClick = () => (open ? hideNavigation() : showNavigation());
  const location = useLocation();

  const isAski = Boolean(location.pathname.startsWith("/asiakirjat"));

  return <>{!isAski && <BurgerIcon onClick={onClick} open={open} />}</>;
};

const NavigationContainer: FunctionComponent<{
  hasStatusMessage: boolean;
  isAppVersionVisible: boolean;
  open: boolean;
  showStatusMessage: () => void;
}> = props => {
  const location = useLocation();

  const isAski = Boolean(location.pathname.startsWith("/asiakirjat"));

  return (
    <div
      className={classNames(styles.mainNavStyle, {
        [styles.mainNavStatusMessage]: !props.open && props.hasStatusMessage
      })}
    >
      {props.children}

      <div className={styles.burgerContainer}>
        <BurgerNavigationIcon />
        {!props.open && props.hasStatusMessage && (
          <div className={styles.statusMessageIconContainer}>
            <PlainButton onClick={props.showStatusMessage}>
              <StatusMessageIcon className={styles.statusMessageIcon} />
            </PlainButton>
          </div>
        )}
      </div>

      {props.isAppVersionVisible && (
        <AppVersion
          className={classnames(styles.version, {
            [styles.aski]: isAski
          })}
        />
      )}
    </div>
  );
};

export const UntranslatedDesktopNavBar: FunctionComponent<
  WithTranslations<{}, typeof defaultDesktopNavBarTranslations> & HasContainers
> = withAuthentication(({ translations = defaultDesktopNavBarTranslations, ...props }) => {
  const location = useLocation();

  const isOnAdminPage = location.pathname.match(/^\/admin/gi);
  const isOnOrdersPage = location.pathname.match(/^\/orders(?!\/road|\/rail)/gi);
  const isOnReportPage = location.pathname.match(/^\/report/gi);
  const isOnSearchPage = location.pathname.match(/^\/search/gi);
  const isOnShuntingPage = location.pathname.match(/^\/shunting/gi);
  const isAski = Boolean(location.pathname.startsWith("/asiakirjat"));

  const { open: navigationOpen, hideNavigation } = useContext(NavigationStateContext);
  const { open: statusMessageOpen, showStatusMessage } = useContext(StatusMessageStateContext);
  const hasStatusMessage =
    translations.statusMessageRail !== "undefined" || translations.statusMessageRoad !== "undefined" || !isAski;

  const {
    email,
    reports = [],
    isAdUser,
    isAdminInSubuserGroup,
    isAdminInUserGroup,
    isGlobalAdmin,
    userGroups,
    subuserGroups,
    visit
  } = useUserInformation();

  const isSignedIn = Boolean(email);

  const shouldSeeManagementLink = Boolean(isAdminInUserGroup || isAdminInSubuserGroup || isAdUser);

  const shuntingAllowed =
    isGlobalAdmin ||
    userGroups?.some(g => {
      return g.allowOrderShunting;
    }) ||
    isAdUser ||
    subuserGroups?.some(g => {
      return g.allowOrderShunting;
    }) ||
    visit?.allowOrderShunting;

  const selfShuntingAllowed = 
    isGlobalAdmin ||
    userGroups?.some(g => {
      return g.allowSelfShunting;
    }) ||
    isAdUser ||
    subuserGroups?.some(g => {
      return g.allowSelfShunting;
    }) || 
    visit?.allowSelfShunting;

  useEffect(() => {
    hideNavigation();
  }, [hideNavigation, location]);

  return (
    <div className={styles.desktopNavbarContainer}>
      <LinkToHome className={styles.desktopLogo} />
      {!isAski && (
        <div className={styles.navLinks}>
          {isSignedIn && (
            <>
              <InlineLink
                className={classnames(styles.search, {
                  [styles.active]: isOnSearchPage && !navigationOpen
                })}
                to={`/search`}
              >
                {translations.search}
              </InlineLink>
              <OrderingUsersOnly>
                <InlineLink
                  className={classnames(styles.orders, {
                    [styles.active]: isOnOrdersPage && !navigationOpen
                  })}
                  to={`/orders`}
                >
                  {translations.orders}
                </InlineLink>
              </OrderingUsersOnly>
              {(shuntingAllowed || selfShuntingAllowed) && (
                <InlineLink
                  className={classnames(styles.shunting, {
                    [styles.active]: isOnShuntingPage && !navigationOpen
                  })}
                  to={`/shunting`}
                >
                  {translations.shunting}
                </InlineLink>
              )}
              {shouldSeeManagementLink && (
                <InlineLink
                  className={classnames(styles.admin, {
                    [styles.active]: isOnAdminPage && !navigationOpen
                  })}
                  to={`/admin/user-groups`}
                >
                  {translations.management}
                </InlineLink>
              )}
              {reports.length > 0 && (
                <InlineLink
                  className={classnames(styles.reports, {
                    [styles.active]: isOnReportPage && !navigationOpen
                  })}
                  to={`/reports`}
                >
                  {translations.reports}
                </InlineLink>
              )}

              <div className={styles.separator} />
            </>
          )}

          <div className={styles.iconContainer}>
            <PersonIcon
              className={classnames(styles.icon, {
                [styles.navigationActive]: navigationOpen
              })}
            />
            {!statusMessageOpen && hasStatusMessage && (
              <div className={styles.statusMessageIconContainer}>
                <PlainButton onClick={showStatusMessage}>
                  <StatusMessageIcon className={styles.statusMessageIcon} />
                </PlainButton>
              </div>
            )}
          </div>
        </div>
      )}

      <AppVersion className={styles.desktopVersion} />
    </div>
  );
});

export const NavBar = addTranslations(UntranslatedNavBar, {
  orderNumber: { id: "navigation.orderNumber" },
  statusMessageRail: { id: "main.statusMessageRail" },
  statusMessageRoad: { id: "main.statusMessageRoad" }
});

export const DesktopNavBar = addTranslations(UntranslatedDesktopNavBar, {
  search: { id: "navigation.search" },
  management: { id: "navigation.management" },
  orders: { id: "navigation.orders" },
  shunting: { id: "navigation.shunting" },
  reports: { id: "navigation.reports" },
  statusMessageRail: { id: "main.statusMessageRail" },
  statusMessageRoad: { id: "main.statusMessageRoad" }
});
