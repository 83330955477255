import { IntlShape } from "react-intl";
import React, { useContext, ComponentProps } from "react";
import { IntlContext } from "./locale-provider";
import { LocalisedMessages } from "./types";
import { modifyValues } from "../../utils";
type TranslateFn = <Keys extends { [key: string]: any }>(
  intl: IntlShape,
  translations: Record<keyof Keys, { id: keyof LocalisedMessages; values?: {} }>
) => Record<keyof Keys, string>;

export const translate: TranslateFn = (intl, translations) => {
  const translated = {} as Record<keyof typeof translations, string>;

  for (const translationId in translations) {
    const { id, values } = translations[translationId];
    translated[translationId] = intl.formatMessage({ id }, values);
  }

  return translated;
};

type Translator = <Props extends { translations: Record<string, string> }>(
  Comp: React.ComponentType<Props>,
  translations: Record<
    keyof Props["translations"],
    {
      id: keyof LocalisedMessages;
      values?: Record<string, (p: Props) => string>;
    }
  >
) => React.ComponentType<Omit<Props, "translations">> & {
  Unlocalised: React.ComponentType<Omit<Props, "translations"> & { translations?: Record<string, string> }>;
};

type InvokeTranslationsFn = <Props, T extends Record<string, { values?: Record<string, (p: Props) => string> }>>(
  p: Props,
  translations: T
) => Record<string, T[keyof T] & { values: Record<string, string> }>;

const invokeTranslationValues: InvokeTranslationsFn = (props, translations) => {
  return modifyValues(translations, translation => {
    return {
      ...translation,
      values: translation.values ? modifyValues(translation.values, valueFn => valueFn(props)) : undefined
    };
  });
};

export const addTranslations: Translator = (Comp, translations) => {
  return Object.defineProperty(
    (props: any) => {
      // intl is undefined if no provider is given
      const intl = useContext(IntlContext) as IntlShape | undefined;
      const Untyped = Comp as React.ComponentType<any>;

      try {
        const evaledTranslations = invokeTranslationValues(props, translations);
        const translated = intl?.formatMessage ? translate(intl, evaledTranslations as any) : undefined;

        return <Untyped translations={translated} {...props} />;
      } catch (e) {
        console.error(e);

        return <Untyped translations={undefined} {...props} />;
      }
    },
    "Unlocalised",
    {
      value: (props: ComponentProps<typeof Comp>) => <Comp usePresentational={true} {...props} />,
      writable: false,
      enumerable: false
    }
  );
};

type ChooseContainerOrPresentational = <P extends {}>(
  props: {
    Comp: React.ComponentType<P> & {
      Unlocalised: React.ComponentType<P>;
    };
    usePresentational?: boolean;
  } & P
) => React.ReactElement;

export const ChooseType: ChooseContainerOrPresentational = ({ usePresentational, Comp, ...props }) =>
  usePresentational ? <Comp.Unlocalised {...(props as any)} /> : <Comp {...(props as any)} />;
