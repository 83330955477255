import React, { FunctionComponent, ReactNode } from "react";
import { withCanBackNavigate, BackProps } from "../../router/withCanBackNavigate";
import { Link } from "../Atoms/Link";
import classnames from "classnames";
import styles from "./back-button.module.scss";
import { useIsDesktop } from "../../utils/use-is-desktop";
import { ArrowIcon } from "../Icons";
import { WithTranslations, HasContainers } from "../../services/localisation/types";
import { addTranslations } from "../../services/localisation/utils";
import { useLocation } from "react-router";

const defaultTranslations = {
  back: "Back"
};

const Button: FunctionComponent<
  WithTranslations<
    { backgroundColor?: "transparent"; className?: string; persistQueryParams?: boolean } & BackProps,
    typeof defaultTranslations
  > &
    HasContainers
> = ({ translations = defaultTranslations, canBackNavigate, navigateBack, className, backgroundColor, ...props }) => {
  const isDesktop = useIsDesktop();
  const location = useLocation<any>();
  const fromChildRoute = location.state?.isFromChildRoute;

  if (fromChildRoute) {
    return <div></div>;
  }

  return (
    <div className={classnames(styles.container, { [styles.transparent]: backgroundColor === "transparent" })}>
      <Link
        onClick={e => {
          if (canBackNavigate) {
            e.preventDefault();
            navigateBack();
          }
        }}
        className={classnames(styles.closeLink, className)}
        to={{
          pathname: props.returnPath,
          state: { isFromChildRoute: false, isFromBackButton: true },
          search: props.persistQueryParams ? location.search : ""
        }}
        replace
      >
        <ArrowIcon className={styles.arrowIcon} direction="left" />
        {isDesktop && <span className={styles.closeText}>{translations.back}</span>}
      </Link>
    </div>
  );
};

const UntranslatedBackButton = withCanBackNavigate(Button);

export const BackButton = addTranslations(UntranslatedBackButton, {
  back: { id: "action.back" }
});

const BackButtonCmp: FunctionComponent<{ children: ReactNode } & BackProps> = props => {
  return (
    <Link
      onClick={e => {
        if (props.canBackNavigate) {
          e.preventDefault();
          props.navigateBack();
        }
      }}
      to={{
        pathname: props.returnPath,
        state: { isFromChildRoute: false, isFromBackButton: true }
      }}
      replace
    >
      {props.children}
    </Link>
  );
};

export const BackButtonHOC = withCanBackNavigate(BackButtonCmp);
