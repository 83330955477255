import { EnvOpts } from ".";

export const config: EnvOpts = {
  useTestingToken: false,
  testingToken: "",
  userPoolAskiWebClientId: "65qj4fohcvfl424qqv5vpgpr43",
  cognito: {
    region: "eu-west-1",
    userPoolId: "eu-west-1_Ycv4OiLNW",
    userPoolWebClientId: "564enc6eafhmg17j385ierrv9i",
    userIdentityProvider: "asiakas360-dev-vr-ad-users",
    adminIdentityProvider: "asiakas360-dev-vr-ad-admins",
    domain: "asiakas360-dev.auth.eu-west-1.amazoncognito.com"
  },
  api: {
    url: "https://d36o8lpzlfabre.cloudfront.net/api"
  }
};
