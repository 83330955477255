export function updateArrayAtIndex<T>(array: T[], index: number, value: T): T[] {
  const updatedArray = [...array];
  updatedArray[index] = value;

  return updatedArray;
}

export function removeAtIndex<T>(array: T[], index: number) {
  const updatedArray = [...array];
  updatedArray.splice(index, 1);

  return updatedArray;
}

export function uniqueStrings(list: string[]) {
  return Array.from(new Set(list));
}

export function sum(nums: number[]): number {
  return nums.reduce((total, num) => total + num, 0);
}

export function includes(array: string[], value: string | string[]) {
  return Array.isArray(value) ? value.some(val => array.includes(val)) : array.includes(value);
}
