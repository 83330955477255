import { EntityType, IOrder, IPagedOrders } from "../combined-types";
import { AxiosResponse } from "axios";
import { cachedGet, safe, PaginationOptions, DateOptions } from "./utils";
import { makeCacheable } from "../../../utils/use-cacheable";
import { isDefined } from "../../../utils";
import { AlsoCacheFn } from "../../../utils/use-cacheable/create-cacheable-fn";
import { withOtm } from "../utils";
import { apiClient } from "./client";


export const rawFetchOrder = async (customerId: string, orderId: string, entity: EntityType): Promise<IOrder> =>
  cachedGet<IOrder>(safe`/${entity}/orders/by-id/${customerId}/${orderId}`, {
    params: withOtm(entity)
  }).then(r => r.data);

const rawFetchMultipleOrders = async (
  orders: {
    customerId: string;
    orderId: string;
    entity: EntityType;
  }[]
): Promise<PromiseSettledResult<IOrder>[]> =>
  Promise.allSettled(orders.map(o => fetchOrder(o.customerId, o.orderId, o.entity)()));

export const fetchMultipleOrders = makeCacheable(rawFetchMultipleOrders, 5 * 60 * 1000);

export const fetchOrder = makeCacheable(rawFetchOrder, 5 * 60 * 1000);

export const rawFetchOrders = async (
  customerId: string,
  entity: EntityType,
  options: PaginationOptions & DateOptions = {}
): Promise<IPagedOrders> => {
  const date = options.date && options.date.toISOString().slice(0, 10);

  return cachedGet<IPagedOrders>(safe`/${entity}/orders/by-date/${customerId}`, {
    params: { ...options, date: date, ...withOtm(entity) }
  }).then(r => r.data);
};

export const rawFetchOrderLists = async (
  ...orders: {
    customerId: string;
    entity: EntityType;
    options?: PaginationOptions & DateOptions;
  }[]
): Promise<PromiseSettledResult<IPagedOrders>[]> => {
  return Promise.allSettled(orders.map(o => rawFetchOrders(o.customerId, o.entity, o.options)));
};

const cacheToOrders: AlsoCacheFn<typeof rawFetchOrders, typeof rawFetchOrder> = (args, result) => {
  return result.result.map(o => [fetchOrder(args[0], o.orderId, args[1]), { value: o, expiresAt: 0 }] as const);
};


export const fetchWagonStatusMultipleCustomers = async (customerIds: string[]): Promise<AxiosResponse<API.IRailOrder[]>> => {
  return apiClient.post(safe`/shuntings/wagon-status`, customerIds)
};

export const fetchOrders = makeCacheable(rawFetchOrders, 5 * 60 * 1000, cacheToOrders);

const cacheToOrderLists: AlsoCacheFn<typeof rawFetchOrderLists, typeof rawFetchOrders> = (args, result) => {
  const lists = result
    .map((v, i) => {
      const arg = args[i];

      return arg && withArgs(arg, v);
    })
    .filter(isDefined)
    .map(onlyResults)
    .filter(isDefined);

  const cachedLists = lists.map(l => {
    const fn = fetchOrders(l.args.customerId, l.args.entity, l.args.options);
    const expiresAt = Date.now() + fn.cacheDurationMs;

    return [fn, { value: l.result, expiresAt }] as const;
  });

  return cachedLists;
};

export const fetchOrderLists = makeCacheable(rawFetchOrderLists, 5 * 60 * 1000, cacheToOrderLists);

export const fetchOrderLocations = async (
  customerId: string,
  orderId: string,
  entity: EntityType
): Promise<AxiosResponse<API.ILocations>> => cachedGet(safe`/${entity}/orders/locations/${customerId}/${orderId}`);

const withArgs = <Args, Result>(args: Args, result: Result) => ({
  args,
  result
});

const onlyResults = <Args, Result>(r: {
  args: Args;
  result: PromiseSettledResult<Result>;
}): { args: Args; result: Result } | undefined => {
  if (r.result.status === "rejected") {
    return undefined;
  }

  return { ...r, result: r.result.value };
};
