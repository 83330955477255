import { cachedGet, safe } from "./utils";
import { makeCacheable } from "../../../utils/use-cacheable";

export const rawFetchStationByUicCode = async (stationUicCode: string): Promise<API.IStation> => {
  return cachedGet<API.IStation>(safe`/rail/stations/by-uic/${stationUicCode}`).then(r => r.data);
};

export const fetchStationByUicCode = makeCacheable(rawFetchStationByUicCode, 5 * 60 * 1000);

export const rawFetchStationsByFavorites = async (): Promise<API.IStationsResponse> => {
  return cachedGet<API.IStationsResponse>(safe`/rail/stations/by-favorites`).then(r => r.data);
};

export const fetchStationsByFavorites = makeCacheable(rawFetchStationsByFavorites, 5 * 60 * 1000);

export const rawFetchAllStations = async (): Promise<API.IStationsResponse> => {
  return cachedGet<API.IStationsResponse>(safe`/rail/stations/all`).then(r => r.data);
};

export const fetchAllStations = makeCacheable(rawFetchAllStations, 5 * 60 * 1000);

export const rawFetchStationList = async (): Promise<API.IStationListResponse> => {
  return cachedGet<API.IStationListResponse>(safe`/rail/stations/list`).then(r => r.data);
};

export const fetchStationList = makeCacheable(rawFetchStationList, 5 * 60 * 1000);
