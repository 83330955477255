import { AxiosResponse } from "axios";
import { isDefined } from "../../../utils";
import { apiClient } from "./client";
import { safe } from "./utils";

export const getRailOrders = async (
  customerNumber: string,
  from: Date,
  to: Date
): Promise<AxiosResponse<API.IRailOrderList>> => {
  const params = new URLSearchParams();

  params.append("customerNumber", customerNumber);
  params.append("includeDrafts", "true");

  if (isDefined(from)) {
    params.append("from", from.toISOString());
  }

  if (isDefined(to)) {
    params.append("to", to.toISOString());
  }

  return apiClient.get(`orders/rail?${params.toString()}`);
};

export const getRailOrdersByCustomerNumbers = async (
  customerNumbers: string[],
  sortBy: API.RailOrderListSort,
  sortDirection: API.RailOrderListSortDirection,
  offset: number,
  limit: number
): Promise<AxiosResponse<API.IPagedRailOrdersByCustomerNumbers>> => {
  const params = new URLSearchParams();

  params.append("customerNumbers", customerNumbers.join(","));
  params.append("sortBy", sortBy);
  params.append("sortDir", `${sortDirection}`);
  params.append("offset", `${offset}`);
  params.append("limit", `${limit}`);

  return apiClient.get(`orders/rail/by-customernumbers?${params.toString()}`);
};

export const getRailOrdersByOrderNumbers = async (orderNumbers: string[]): Promise<AxiosResponse<API.IRailOrder[]>> => {
  return await apiClient.post(safe`/shuntings/fetch/transport-orders`, orderNumbers);
};

export const getRailOrderByOrderNumber = async (
  customerNumber: string,
  orderNumber: string
): Promise<AxiosResponse<API.IRailOrderWithStatus>> => {
  return apiClient.get(safe`/orders/rail/by-ordernumber/${customerNumber}/${orderNumber}`);
};

export const getRailOrderByDossierNumber = async (
  customerNumber: string,
  dossierNumber: string
): Promise<AxiosResponse<API.IRailOrderWithStatus>> => {
  return apiClient.get(safe`/orders/rail/by-dossiernumber/${customerNumber}/${dossierNumber}`);
};

export const modifyRailOrder = async (
  customerNumber: string,
  orderNumber: string,
  order: any
): Promise<AxiosResponse<API.IRailOrderResponse>> => {
  return apiClient.put(safe`/orders/rail/by-ordernumber/${customerNumber}/${orderNumber}`, order);
};

export const deleteRailOrder = async (
  customerNumber: string,
  orderNumber: string
): Promise<AxiosResponse<API.IRailOrderResponse>> => {
  return apiClient.delete(safe`/orders/rail/${customerNumber}/${orderNumber}`);
};

export const postRailOrder = async (order: any): Promise<AxiosResponse<API.IRailOrderResponse>> => {
  return apiClient.post(safe`/orders/rail`, order);
};

export const postRailOrderDraft = async (order: any): Promise<AxiosResponse<API.IRailOrderResponse>> => {
  return apiClient.post(safe`/orders/rail/drafts`, order);
};

export const deleteRailOrderDraft = async (
  customerNumber: string,
  dossierNumber: string
): Promise<AxiosResponse<API.IRailOrderResponse>> => {
  return apiClient.delete(safe`/orders/rail/drafts/${customerNumber}/${dossierNumber}`);
};

export const modifyRailOrderDraft = async (
  customerNumber: string,
  dossierNumber: string,
  order: any
): Promise<AxiosResponse<API.IRailOrderResponse>> => {
  return apiClient.put(safe`/orders/rail/drafts/${customerNumber}/${dossierNumber}`, order);
};
