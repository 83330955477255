import React, { FunctionComponent } from "react";
import { WithTranslations, HasContainers } from "../../services/localisation/types";
import { addTranslations } from "../../services/localisation/utils";
import { withAuthentication } from "../../services/authentication/authentication.provider";
import { formatTime } from "../../utils";
import styles from "./user-group-visit.module.scss";
import { useUserGroupVisit } from "../../utils/use-user-group-visits";
import { LoaderModal } from "../Modal/loader-modal";
import { Button } from "../Button/button";
import { CloseIcon, PasswordViewIcon } from "../Icons";

const defaultTranslations = {
  visitingInGroup: "You are visiting in group",
  visitingInSubGroup: "You are visiting in subuser group",
  visitEndsAt: "Visit ends at",
  endVisit: "End visit",
  errorOnEndVisit: "Error while ending visit",
  pleaseTryAgain: "Please try again"
};

const UntranslatedUserGroupVisitInfo: FunctionComponent<
  WithTranslations<{}, typeof defaultTranslations> & HasContainers
> = withAuthentication(({ translations = defaultTranslations, ...props }) => {
  const { error, loading, unsubscribe } = useUserGroupVisit();

  const visit = props.auth.userInformation?.visit;

  return (
    <>
      {visit && (
        <div className={styles.container}>
          {!loading && (
            <div className={styles.visitInfoContainer}>
              <div className={styles.visitInfo}>
                {error !== undefined ? (
                  <>
                    <p className={styles.text}>{translations.errorOnEndVisit}!</p>
                    <p className={styles.text}>{translations.pleaseTryAgain}.</p>
                  </>
                ) : (
                  <div className={styles.activeVisit}>
                    <PasswordViewIcon className={styles.eyeIcon} />
                    <div>
                      <p className={styles.text}>
                        {visit.subuserGroupName && (
                          <>
                            {translations.visitingInSubGroup} "{visit.subuserGroupName}"
                          </>
                        )}

                        {!visit.subuserGroupName && (
                          <>
                            {translations.visitingInGroup} "{visit.userGroupName}"
                          </>
                        )}
                      </p>
                      <time className={styles.time}>
                        {translations.visitEndsAt} {formatTime(new Date(visit.validUntil))}
                      </time>
                    </div>
                  </div>
                )}
              </div>
              <Button className={styles.button} onClick={unsubscribe}>
                <span className={styles.endVisit}>{translations.endVisit}</span>
                <CloseIcon className={styles.closeIcon} />
              </Button>
            </div>
          )}
        </div>
      )}
      <LoaderModal isLoading={loading} />
    </>
  );
});

export const UserGroupVisitInfo = addTranslations(UntranslatedUserGroupVisitInfo, {
  visitingInGroup: { id: "userGroup.visit.visitingInGroup" },
  visitingInSubGroup: { id: "userGroup.visit.visitingInSubuserGroup" },
  visitEndsAt: { id: "userGroup.visit.visitEndsAt" },
  endVisit: { id: "userGroup.visit.end" },
  errorOnEndVisit: { id: "userGroup.visit.errorWhileEnding" },
  pleaseTryAgain: { id: "error.pleaseTryAgain" }
});
