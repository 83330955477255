import { FC } from "react";
import { useUserInformation } from "../../../utils/use-user-information";

export const OrderingUsersOnly: FC = ({ children }) => {
  const { customers } = useUserInformation();

  if (customers?.some(customer => customer.isAllowedToOrder)) {
    return <>{children}</>;
  }

  return null;
};
