import React, { useContext, SVGAttributes, useMemo } from "react";
import classnames from "classnames";
import { FunctionComponent } from "react";
import { IconProps } from ".";
import { OrderStatus } from "../../services/api/selectors";
import { NavigationStateContext } from "../Navigation";
import { PlainButton } from "../Button/button";
import { useMount } from "react-use";

import styles from "./icon.module.scss";

import { ReactComponent as SvgArrivalIcon } from "./svg/arrival-icon.svg";
import { ReactComponent as SvgArrowIcon } from "./svg/icon-arrow-chevron-chevron.svg";
import { ReactComponent as SvgBookIcon } from "./svg/kuljetus.svg";
import { ReactComponent as SvgBorderOfficerIcon } from "./svg/border-officer.svg";
import { ReactComponent as SvgBundleIcon } from "./svg/bundle.svg";
import { ReactComponent as SvgCalendarIcon } from "./svg/calendar.svg";
import { ReactComponent as SvgCanceledIcon } from "./svg/canceled.svg";
import { ReactComponent as SvgCheckmarkIcon } from "./svg/checkmark.svg";
import { ReactComponent as SvgCheckmark2Icon } from "./svg/checkmark2.svg";  //TODO could investigate if SvgCheckmarkIcon could be replaced with this everywhere
import { ReactComponent as SvgClockIcon } from "./svg/kello.svg";
import { ReactComponent as SvgCloseIcon } from "./svg/ruksi.svg";
import { ReactComponent as SvgCopyIcon } from "./svg/copy.svg";
import { ReactComponent as SvgCrossIcon } from "./svg/cross.svg";
import { ReactComponent as SvgDepartureIcon } from "./svg/departure-icon.svg";
import { ReactComponent as SvgDraftIcon } from "./svg/draft.svg";
import { ReactComponent as SvgDurationIcon } from "./svg/duration.svg";
import { ReactComponent as SvgExclamationMarkIcon } from "./svg/exclamation-mark.svg";
import { ReactComponent as SvgExpandIcon } from "./svg/expand.svg";
import { ReactComponent as SvgEyeIcon } from "./svg/eye.svg";
import { ReactComponent as SvgEyeOffIcon } from "./svg/eye-off.svg";
import { ReactComponent as SvgFilterIcon } from "./svg/filter.svg";
import { ReactComponent as SvgFlagIcon } from "./svg/lippu.svg";
import { ReactComponent as SvgGpsIcon } from "./svg/loading.svg";
import { ReactComponent as SvgInfoIcon } from "./svg/info-circle.svg";
import { ReactComponent as SvgListIcon } from "./svg/list.svg";
import { ReactComponent as SvgLocationIcon } from "./svg/sijainti.svg";
import { ReactComponent as SvgLongArrowIcon } from "./svg/arrow-long-right.svg";
import { ReactComponent as SvgMessageIcon } from "./svg/kommentti.svg";
import { ReactComponent as SvgModifyIcon } from "./svg/modify.svg";
import { ReactComponent as SvgMoreIcon } from "./svg/more.svg";
import { ReactComponent as SvgPasswordHideIcon } from "./svg/password-hide.svg";
import { ReactComponent as SvgPasswordViewIcon } from "./svg/password-view.svg";
import { ReactComponent as SvgPenIcon } from "./svg/kyna.svg";
import { ReactComponent as SvgPersonIcon } from "./svg/person.svg";
import { ReactComponent as SvgPhoneIcon } from "./svg/puhelin.svg";
import { ReactComponent as SvgPlusIcon } from "./svg/plussa.svg";
import { ReactComponent as SvgPrintIcon } from "./svg/print.svg";
import { ReactComponent as SvgRefreshIcon } from "./svg/loading.svg";
import { ReactComponent as SvgRailDeliveryIcon } from "./svg/vaunu-purku.svg";
import { ReactComponent as SvgRailPickupIcon } from "./svg/vaunu-lastaus.svg";
import { ReactComponent as SvgRoadDeliveryIcon } from "./svg/road-purku.svg";
import { ReactComponent as SvgRoadPickupIcon } from "./svg/road-lastaus.svg";
import { ReactComponent as SvgSadIcon } from "./svg/sad.svg";
import { ReactComponent as SvgSearchIcon } from "./svg/search.svg";
import { ReactComponent as SvgShortArrowIcon } from "./svg/arrow-short-right.svg";
import { ReactComponent as SvgThinShortLeftArrowIcon } from "./svg/arrow-thin-short-left.svg";
import { ReactComponent as SvgStarIcon } from "./svg/icon-star-empty.svg";
import { ReactComponent as SvgStatusMessageIcon } from "./svg/exclamation-mark-modified.svg";
import { ReactComponent as SvgSwapIcon } from "./svg/swap.svg";
import { ReactComponent as SvgTrackIcon } from "./svg/track.svg";
import { ReactComponent as SvgTrainIcon } from "./svg/juna.svg";
import { ReactComponent as SvgTrashcanIcon } from "./svg/trashcan.svg";
import { ReactComponent as SvgTruckIcon } from "./svg/rekka.svg";
import { ReactComponent as SvgTooltip } from "./svg/tooltip.svg";
import { ReactComponent as SvgVakIcon } from "./svg/vak.svg";
import { ReactComponent as SvgVrTranspointLogo } from "./svg/vr-transpoint-logo.svg";
import { ReactComponent as SvgWagonIcon } from "./svg/wagon-empty.svg";
import { ReactComponent as SvgWagonLoadedIcon } from "./svg/wagon-loaded.svg";
import { ReactComponent as SvgWaybillIcon } from "./svg/rahtikirja.svg";
import { ReactComponent as SvgWaybillMissingIcon } from "./svg/rahtikirja-puuttuu.svg";
import { ReactComponent as SvgRoadIcon } from "./svg/tiet.svg";
import { ReactComponent as SvgRailsIcon } from "./svg/raiteet.svg";
import { ReactComponent as SvgWeightLightIcon } from "./svg/ei_punnittu.svg";
import { ReactComponent as SvgPPVIcon } from "./svg/ppv-icon.svg";
import { ReactComponent as SvgInfoCircleFilledIcon } from "./svg/info-circle-filled.svg";
import { ReactComponent as SvgTickFilledIcon } from "./svg/tick-filled.svg";
import { ReactComponent as SvgBoundWagonIcon } from "./svg/bound-wagon-icon.svg";
import { ReactComponent as SvgAlertFilledIcon } from "./svg/AlertFilled.svg";
import { ReactComponent as SvgInfoCircleFilledBlackIcon } from "./svg/info-circle-filled-black.svg";
import { ReactComponent as SvgCancelledWhiteIcon } from "./svg/cancelledWhite.svg";


type SvgComponent = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
>;

export const AlertFilled = asIcon(SvgAlertFilledIcon)

export const ArrivalIcon = asIcon(SvgArrivalIcon);

export const BorderOfficerIcon = asIcon(SvgBorderOfficerIcon);

export const BookIcon = asIcon(SvgBookIcon);

export const BundleIcon = asIcon(SvgBundleIcon);

export const DurationIcon = asIcon(SvgDurationIcon);

export const FlagIcon = asIcon(SvgFlagIcon);

export const CalendarIcon = asIcon(SvgCalendarIcon);

export const CanceledIcon = asIcon(SvgCanceledIcon);

export const CancelledWhiteIcon = asIcon(SvgCancelledWhiteIcon);

export const CheckmarkIcon = asIcon(SvgCheckmarkIcon);

export const Checkmark2Icon = asIcon(SvgCheckmark2Icon);

export const ClockIcon = asIcon(SvgClockIcon);

export const CloseIcon = asIcon(SvgCloseIcon);

export const CrossIcon = asIcon(SvgCrossIcon);

export const CopyIcon = asIcon(SvgCopyIcon);

export const DepartureIcon = asIcon(SvgDepartureIcon);

export const DraftIcon = asIcon(SvgDraftIcon);

export const ExclamationMarkIcon = asIcon(SvgExclamationMarkIcon);

export const ExpandIcon = asIcon(SvgExpandIcon);

export const EyeIcon = asIcon(SvgEyeIcon);

export const EyeOffIcon = asIcon(SvgEyeOffIcon);

export const FilterIcon = asIcon(SvgFilterIcon);

export const GpsIcon = asIcon(SvgGpsIcon);

export const InfoIcon = asIcon(SvgInfoIcon);

export const InfoCircleFilledBlackIcon = asIcon(SvgInfoCircleFilledBlackIcon);

export const ListIcon = asIcon(SvgListIcon);

export const LocationIcon = asIcon(SvgLocationIcon);

export const LongArrowIcon = asIcon(SvgLongArrowIcon);

export const MessageIcon = asIcon(SvgMessageIcon);

export const ModifyIcon = asIcon(SvgModifyIcon);

export const MoreIcon = asIcon(SvgMoreIcon);

export const PasswordHideIcon = asIcon(SvgPasswordHideIcon);

export const PasswordViewIcon = asIcon(SvgPasswordViewIcon);

export const PenIcon = asIcon(SvgPenIcon);

export const PhoneIcon = asIcon(SvgPhoneIcon);

export const PlainCloseIcon = SvgCloseIcon;

export const PlusIcon = asIcon(SvgPlusIcon);

export const PrintIcon = asIcon(SvgPrintIcon);

export const RefreshIcon = asIcon(SvgRefreshIcon);

export const RailPickupIcon = asIcon(SvgRailPickupIcon);

export const RailDeliveryIcon = asIcon(SvgRailDeliveryIcon);

export const RoadPickupIcon = asIcon(SvgRoadPickupIcon);

export const RoadDeliveryIcon = asIcon(SvgRoadDeliveryIcon);

export const SadIcon = asIcon(SvgSadIcon);

export const SearchIcon = asIcon(SvgSearchIcon);

export const ShortArrowIcon = asIcon(SvgShortArrowIcon);

export const StarIcon = asIcon(SvgStarIcon);

export const StatusMessageIcon = asIcon(SvgStatusMessageIcon);

export const SwapIcon = asIcon(SvgSwapIcon);

export const TooltipIcon = asIcon(SvgTooltip);

export const TrackIcon = asIcon(SvgTrackIcon);

export const TrainIcon = asIcon(SvgTrainIcon);

export const TrashcanIcon = asIcon(SvgTrashcanIcon);

export const TruckIcon = asIcon(SvgTruckIcon);

export const VakIcon = asIcon(SvgVakIcon);

export const VrTranspointLogo = asIcon(SvgVrTranspointLogo);

export const WagonIcon = asIcon(SvgWagonIcon);

export const WagonLoadedIcon = asIcon(SvgWagonLoadedIcon);

export const WaybillIcon = asIcon(SvgWaybillIcon);

export const WaybillMissingIcon = asIcon(SvgWaybillMissingIcon);

export const RoadIcon = asIcon(SvgRoadIcon);

export const RailsIcon = asIcon(SvgRailsIcon);

export const WeightLightIcon = asIcon(SvgWeightLightIcon);

export const PPVIcon = asIcon(SvgPPVIcon);

export const InfoCircleFilledIcon = asIcon(SvgInfoCircleFilledIcon);

export const TickFilledIcon = asIcon(SvgTickFilledIcon);

export const BoundWagonIcon = asIcon(SvgBoundWagonIcon);

export type Direction = "down" | "up" | "left" | "right";

const PlainArrowIcon = asIcon(SvgArrowIcon);

export const ArrowIcon: FunctionComponent<IconProps & { direction?: Direction }> = ({ direction = "up", ...props }) => (
  <PlainArrowIcon className={classnames(props.className, styles[direction])} />
);

const ThinShortLeftArrowIcon = asIcon(SvgThinShortLeftArrowIcon);

export const ThinShortArrowIcon: FunctionComponent<IconProps & { direction?: Direction }> = ({ direction = "up", ...props }) => (
  <ThinShortLeftArrowIcon className={classnames(props.className, styles[direction])} />
);

export const DoubleArrowIcon: FunctionComponent<
  IconProps & {
    open: boolean;
    arrowClass?: string;
  }
> = props => (
  <div className={classnames({ [styles.open]: props.open }, props.className)}>
    <div className={styles.top}>
      <ArrowIcon className={classnames(props.arrowClass)} direction="up" />
    </div>

    <div className={styles.bottom}>
      <ArrowIcon className={classnames(props.arrowClass)} direction="down" />
    </div>
  </div>
);

export const IconWithStatus: FunctionComponent<
  IconProps & {
    icon?: React.ComponentType<IconProps>;
    status: OrderStatus;
  }
> = ({ icon: Icon, className, status, ...props }) => (
  <div
    className={classnames(
      styles.status,
      { [styles.planned]: status === "indeterminate" },
      { [styles.ok]: status === "none" },
      { [styles.warning]: status === "slight" },
      { [styles.danger]: status === "severe" },
      { [styles.passive]: status === "passive" },
      className
    )}
  >
    <div className={styles.icon}>{Icon && <Icon className={styles.statusIcon} />}</div>
  </div>
);

export const BurgerIcon: FunctionComponent<{ onClick?: () => void; open: boolean }> = ({ onClick, open }) => {
  return (
    <PlainButton
      className={classnames(styles.burgerIcon, { [styles.active]: open })}
      onClick={e => {
        onClick?.();
        e.currentTarget.blur();
      }}
    >
      <div className={classnames(styles.burgerIconLine, styles.burgerIconLine1)} />
      <div className={classnames(styles.burgerIconLine, styles.burgerIconLine2)} />
      <div className={classnames(styles.burgerIconLine, styles.burgerIconLine3)} />
    </PlainButton>
  );
};

export const LeaningBurgerIcon: FunctionComponent<{ onClick?: () => void; open: boolean }> = ({ onClick, open }) => {
  return (
    <PlainButton
      className={classnames(styles.burgerIcon, { [styles.active]: open })}
      onClick={e => {
        onClick?.();
        e.currentTarget.blur();
      }}
    >
      <div className={classnames(styles.burgerIconLine, styles.burgerIconLine1, styles.leaningBurgerIconLine1)} />
      <div className={classnames(styles.burgerIconLine, styles.burgerIconLine2)} />
      <div className={classnames(styles.burgerIconLine, styles.burgerIconLine3, styles.leaningBurgerIconLine3)} />
    </PlainButton>
  );
};

const PlainPersonIcon = asIcon(SvgPersonIcon);

export const PersonIcon: FunctionComponent<
  IconProps & {
    className?: string;
  }
> = props => {
  const { open, showNavigation, hideNavigation } = useContext(NavigationStateContext);

  return (
    <PlainButton
      className={styles.personIcon}
      onClick={e => {
        open ? hideNavigation() : showNavigation();
        e.currentTarget.blur();
      }}
    >
      <PlainPersonIcon className={props.className} />
    </PlainButton>
  );
};

export type SvgOverride = Partial<SVGAttributes<SVGElement>>;

function asIcon(
  Icon: SvgComponent
): React.ComponentType<{
  className?: string;
  svgOverrides?: SvgOverride;
}> {
  const Wrapped: React.FC<{
    className?: string;
    svgOverrides?: SvgOverride;
  }> = props => {
    const ref = React.createRef<any>();

    /*
      Make each id unique within SVG. This is done because unique IDs per svg are not enough.
      Multiple instances of same SVG will cause e.g. <use> and mask references to break,
      since the same HTML document would contain duplicate IDs. By making each id unique,
      we can ensure that the icons works properly.
    */

    const prefix = useMemo(() => `icon-${Math.floor(Math.random() * 0xffff_ffff).toString(16)}`, []);

    useMount(() => {
      if (ref.current) {
        const current = ref.current as SVGElement;

        const ids = Array.from(current.querySelectorAll("[id]")).map(n => n.id);
        const asUnique = ids.map(id => [id, `${prefix}-${id}`]);

        // Replace references to possibly non-unique ids in the SVG
        const html = asUnique.reduce(
          (html, [id, unique]) =>
            html.replace(new RegExp(`#${id}`, "g"), `#${unique}`).replace(`id="${id}"`, `id="${unique}"`),
          current.innerHTML
        );

        current.innerHTML = html;
      }
    });

    return (
      <div className={classnames(props.className, styles.iconContainer)}>
        <Icon {...props.svgOverrides} ref={ref} />
      </div>
    );
  };

  return Wrapped;
}
