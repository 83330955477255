import React, { FunctionComponent } from "react";
import styles from "./error.module.scss";
import { ErrorData } from "../../utils/error";
import { HasContainers, WithTranslations } from "../../services/localisation/types";
import { addTranslations, ChooseType } from "../../services/localisation/utils";
import { MoreInfo } from "./error-more-info";
import { RoadIcon, RailsIcon } from "../Icons";
import { PageContainer } from "../Atoms/PageContainer";
import { NavBar } from "../NavBar";
import { useIsDesktop } from "../../utils/use-is-desktop";
import { BackButtonHOC } from "../Button/back-button";
import { DevOnly } from "../Controls/EnvControl/env-control";
import { Button } from "../Button/button";

const defaultTranslations = {
  badRequest: "Bad request",
  error: "Error",
  forbidden: "Forbidden",
  notFound: "Not found",
  somethingWentWrong: "Something went wrong",
  unauthorized: "Unauthorized",
  goBack: "Go back",
  pageNotFound: "Page not found"
};

const UntranslatedErrorPage: FunctionComponent<
  WithTranslations<
    {
      error: ErrorData;
      pageNotFound?: boolean;
    },
    typeof defaultTranslations
  > &
    HasContainers
> = ({ translations = defaultTranslations, ...props }) => {
  const errorText = () => {
    if (props.pageNotFound) {
      return translations.pageNotFound;
    }

    if (props.error.statusCode) {
      switch (props.error.statusCode) {
        case 400:
          return translations.badRequest;
        case 401:
          return translations.unauthorized;
        case 403:
          return translations.forbidden;
        case 404:
          return translations.notFound;
        default:
          return translations.somethingWentWrong;
      }
    }

    return translations.somethingWentWrong;
  };

  const isDesktop = useIsDesktop();

  return (
    <>
      {!isDesktop && <NavBar navigationStyle="BACK_BURGER" />}

      <PageContainer className={styles.pageContainer}>
        <div className={styles.svgContainer}>
          <RailsIcon className={styles.railsIcon} />
          <RoadIcon className={styles.roadIcon} />
        </div>
        <div className={styles.contentContainer}>
          <h1 className={styles.header}>
            <span className={styles.headerText}>
              {translations.error} {props.error.statusCode}
            </span>
          </h1>
          <p className={styles.infoHeader}>{errorText()}</p>
          <DevOnly>
            <ChooseType Comp={MoreInfo} error={props.error} usePresentational={props.usePresentational} />
          </DevOnly>
          <BackButtonHOC>
            <Button className={styles.button} color="green" height="medium-height" width="large-width">
              {translations.goBack}
            </Button>
          </BackButtonHOC>
        </div>
      </PageContainer>
    </>
  );
};

export const ErrorPage = addTranslations(UntranslatedErrorPage, {
  badRequest: { id: "error.badRequest" },
  error: { id: "error.error" },
  forbidden: { id: "error.forbidden" },
  notFound: { id: "error.notFound" },
  somethingWentWrong: { id: "error.somethingWentWrong" },
  unauthorized: { id: "error.unauthorized" },
  goBack: { id: "action.returnBack" },
  pageNotFound: { id: "error.pageNotFound" }
});
