import React, { FunctionComponent, useRef } from "react";

import styles from "./loader.module.scss";
import classnames from "classnames";
import lottie from "lottie-web/build/player/lottie_light";
import { useMount, useUnmount } from "react-use";

import animationData from "./vr-loading-animation.json";

type LoaderSize = "small" | "medium" | "large";
type TextColor = "light" | "dark";
type Color = "green" | "white";

export const Loader: FunctionComponent<{
  color?: Color;
  size?: LoaderSize;
  textColor?: TextColor;
}> = ({ color = "green", size = "large", textColor = "dark", ...props }) => {
  const ref = useRef<HTMLDivElement>(null);
  useMount(() => {
    if (ref.current) {
      lottie.loadAnimation({
        name: "loader",
        container: ref.current,
        renderer: "svg",
        animationData,
        loop: true
      });
    }
  });

  useUnmount(() => {
    lottie.destroy("loader");
  });

  return (
    <div className={classnames(styles.loaderContainer, { [styles.lightText]: textColor === "light" }, styles[size])}>
      <div ref={ref} className={classnames(styles.loader, styles[color])}></div>
      {props.children}
    </div>
  );
};

export const LoadingPlaceholder: FunctionComponent<{
  className?: string;
}> = props => <span className={classnames(styles.placeholder, props.className)}>&nbsp;</span>;
