import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import classnames from "classnames";
import styles from "./modal.module.scss";
import { useMount, useUnmount } from "react-use";
import { useCloseModal } from "../../utils/use-close-modal";

const modalRoot = document.getElementById("modal") || document.body.appendChild(document.createElement("div"));

export type ModalProps = {
  children: React.ReactNode;
  isVisible: boolean;
  className?: string;
  theme?: "dark" | "light";
  disableBackgroundBlur?: boolean;
  scrollRef?: React.RefObject<HTMLDivElement>;
  onClose?: (...args: any[]) => void;
};

export const Modal: FunctionComponent<ModalProps> = ({ className, theme = "light", ...props }) => {
  const [el] = useState(document.createElement("div"));

  const containerRef = useRef(null);
  const ref = props.scrollRef || containerRef;

  useCloseModal(() => props.onClose?.(), ref);

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target !== e.currentTarget) {
      return;
    }

    props.onClose?.();
  };

  useEffect(() => {
    if (props.isVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [props.isVisible]);

  useMount(() => {
    if (modalRoot) {
      modalRoot.appendChild(el);
    }
  });

  useUnmount(() => {
    if (modalRoot) {
      modalRoot.removeChild(el);
    }
  });

  const portal = props.isVisible ? (
    <div
      className={classnames(
        styles.modal,
        {
          [styles.dark]: theme === "dark",
          [styles.disableBlur]: props.disableBackgroundBlur
        },
        className
      )}
      ref={ref}
      onClick={handleModalClick}
    >
      {props.children}
    </div>
  ) : (
    <div />
  );

  return ReactDOM.createPortal(portal, el);
};
