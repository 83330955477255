import { AxiosResponse } from "axios";
import { cachedGet, safe } from "./utils";
import { apiClient } from "./client";

export const fetchUserInformation = async (): Promise<AxiosResponse<API.IUserInformation>> => {
  return cachedGet(safe`/user/information`);
};

export const patchUserInformation = async (
  information: API.IUserInformationRequest
): Promise<AxiosResponse<API.IUserInformation>> => {
  return apiClient.patch(safe`/user/information`, information);
};

export const logOut = async (accessToken: string): Promise<AxiosResponse<API.IUserInformation>> => {
  return apiClient.post(safe`/user/logout`, undefined, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
};

export const subscribeToUserGroup = async (userGroupId: string, subuserGroupId?: string): Promise<void> => {
  await apiClient.post(safe`/user/user-group-visits/subscribe`, { userGroupId, subuserGroupId });
};

export const unsubscribeFromUserGroup = async (): Promise<void> => {
  await apiClient.post(safe`/user/user-group-visits/unsubscribe`, {});
};
