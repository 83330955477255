import React, { useContext, FunctionComponent, useEffect } from "react";
import styles from "./App.module.scss";
import { Routes } from "./router";
import { AuthenticationProvider, AuthenticationContext, useUserId } from "./services/authentication/authentication.provider";
import { Authentication } from "./services/authentication/authentication";
import { LocaleProvider } from "./services/localisation/locale-provider";
import { Hub } from "@aws-amplify/core";
import { NavigationStateProvider, NavigationStateContext, Navigation } from "./ui/Navigation";
import { SlideOnAppear } from "./ui/Atoms/animations/appear-slide";
import { DesktopNavBar } from "./ui/NavBar";
import { StatusMessage, StatusMessageStateProvider } from "./ui/Atoms/StatusMessage";
import { GlobalCacheProvider } from "./utils/use-cacheable";
import { GlobalCacheContext } from "./utils/use-cacheable/cache";
import { UserGroupVisitInfo } from "./ui/UserGroupVisit/user-group-visit";
import { BundleStateProvider } from "./ui/GroupedOrderView/bundle-state-provider";
import { useLocalStorage } from "react-use";
import { DevOnly } from "./ui/Controls/EnvControl/env-control";
import { isDevEnv } from "./utils";

const NavigationOrContent: FunctionComponent<{ content: React.ReactNode }> = props => {
  const navigationOpen = useContext(NavigationStateContext).open;
  const { actions } = useContext(AuthenticationContext);

  return (
    <>
      <SlideOnAppear isOpen={navigationOpen} durationMs={150}>
        <Navigation logOut={actions.logout} />
      </SlideOnAppear>

      <div className={navigationOpen ? styles.displayNone : styles.displayBlock}>{props.content}</div>
    </>
  );
};

const OtmMessage: FunctionComponent = props => {
  const [useOtm, setOtm] = useLocalStorage("useOtm");
  const isDev = isDevEnv();

  if (isDev && !useOtm && window.location.href.includes("useOtm=true")) {
    setOtm(true);
    window.history.replaceState(null, "Logistics360 | OTM Mode enabled", window.location.pathname);
  }

  return useOtm ? (
    <div>
      <p>You are in OTM test mode (dev only), which means that some requests will be handled differently.</p>
      <button onClick={() => setOtm(undefined)}>Exit OTM mode</button>
    </div>
  ) : null;
};

const AppBody = () => {
  const userId = useUserId();
  const { clearCache } = useContext(GlobalCacheContext);
  // Clear cache when user id changes, to avoid serving data fetched on another user
  useEffect(() => {
    clearCache();
  }, [clearCache, userId]);

  return (
    <div className={styles.app}>
      <Routes>
        {content => {
          return (
            <>
              <DevOnly>
                <OtmMessage />
              </DevOnly>
              <StatusMessage />
              <UserGroupVisitInfo />
              <DesktopNavBar />
              <NavigationOrContent content={content} />
            </>
          );
        }}
      </Routes>
    </div>
  );
};

const App = () => {
  return (
    <AuthenticationProvider authModule={Authentication} hubModule={Hub as any}>
      <GlobalCacheProvider>
        <LocaleProvider>
          <NavigationStateProvider>
            <StatusMessageStateProvider>
              <BundleStateProvider>
                <AppBody />
              </BundleStateProvider>
            </StatusMessageStateProvider>
          </NavigationStateProvider>
        </LocaleProvider>
      </GlobalCacheProvider>
    </AuthenticationProvider>
  );
};

export default App;
