import React, { FunctionComponent } from "react";
import styles from "./button.module.scss";
import classnames from "classnames";
import { AsCmp } from "../../utils/types";

type ButtonSize = "small" | "small-medium" | "medium" | "large";

type ButtonColor =
  | "green"
  | "blue"
  | "red"
  | "invertedRed"
  | "yellow"
  | "white"
  | "gray"
  | "gunmetal"
  | "darkGray"
  | "transparent"
  | "none";
type ButtonHeight = "large-height" | "medium-height" | "small-height" | "default-height";

export type ButtonWidth = "xl-width" | "large-width" | "medium-width" | "small-width" | "default-width";

export type ButtonComponentProps = {
  size?: ButtonSize;
  color?: ButtonColor;
  height?: ButtonHeight;
  width?: ButtonWidth;
  fullWidth?: boolean;
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  paddingSize?: "small" | "normal";
};

export const Button: AsCmp<"button", ButtonComponentProps> = ({
  as: Cmp = "button" as React.ElementType,
  className,
  size = "medium",
  color = "none",
  height = "default-height",
  width = "default-width",
  paddingSize = "normal",
  fullWidth,
  ...props
}) => {
  const disabledColor = props.disabled ? "disabled" : "none";
  const CastedCmp = Cmp as any; // Bypass TS error (TS2590)

  return (
    <CastedCmp
      {...props}
      role={"button"}
      className={classnames(
        styles.button,
        styles.reset,
        className,
        styles[`padding-${paddingSize}` as const],
        styles[size],
        color !== "none" ? styles[color] : {},
        disabledColor !== "none" ? styles[disabledColor] : {},
        height !== "default-height" ? styles[height] : {},
        width !== "default-width" ? styles[width] : {},
        { [styles.fullWidth]: fullWidth }
      )}
    />
  );
};

export const PlainButton: FunctionComponent<JSX.IntrinsicElements["button"]> = ({ className, ...props }) => (
  /* eslint-disable-next-line */
  <button role="button" className={classnames(className, styles.reset)} {...props} />
);
