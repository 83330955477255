import stableStringify from "json-stable-stringify";
import { PromiseValue } from "../types";
import { CachedValue } from "./cache";

export type CacheableFn<T> = {
  (): Promise<T>;
  cacheable: true;
  cacheKey: string;
  cacheDurationMs: number;
  alsoCache?: (v: T) => CacheResultTuple<any>[];
};

export type CacheResultTuple<T> = readonly [CacheableFn<T>, CachedValue<T>];

type BaseFn = (...args: any[]) => Promise<any>;

export type AlsoCacheFn<InputFn extends BaseFn, OutputFn extends BaseFn> = (
  args: Parameters<InputFn>,
  v: PromiseValue<ReturnType<InputFn>>
) => CacheResultTuple<PromiseValue<ReturnType<OutputFn>>>[];

//eslint-disable-next-line @typescript-eslint/no-unused-vars
type MakeCacheable<AlsoCache = never> = <T extends BaseFn, Y extends BaseFn>(
  fn: T,
  cacheDurationMs: number,
  alsoCache?: AlsoCacheFn<T, Y>
) => (...args: Parameters<T>) => CacheableFn<PromiseValue<ReturnType<T>>>;

const memoizedTriggers: Record<string, CacheableFn<any> | undefined> = {};

export const makeCacheable: MakeCacheable = (fn, cacheDurationMs, alsoCache) => {
  const id = `${fn.name}-${Math.floor(Math.random() * 0xfff_fff).toString(16)}`;

  return (...args) => {
    const key = `${id}-${stableStringify(args)}`;
    const existing = memoizedTriggers[key];

    if (existing) {
      return existing;
    }

    const trigger = () => fn(...args);
    trigger.cacheable = true as const;
    trigger.cacheDurationMs = cacheDurationMs;
    trigger.cacheKey = key;
    trigger.alsoCache = alsoCache?.bind(null, args);

    memoizedTriggers[key] = trigger;

    return trigger;
  };
};
