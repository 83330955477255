import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { fetchServerTime, subscribeToUserGroup, unsubscribeFromUserGroup } from "../services/api";
import { useUserInformation } from "./use-user-information";
import { useEvent } from "react-use";
import { differenceInMilliseconds } from "date-fns";

export const useUserGroupVisit = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(undefined);

  const history = useHistory();

  const { actions, visit } = useUserInformation();

  const checkVisitValidity = useCallback(async () => {
    if (visit) {
      if (visit.validUntil < new Date().toISOString()) {
        await actions.refreshSession();
        window.location.reload();
      }
    }
  }, [actions, visit]);

  useEffect(() => {
    (async () => {
      if (!visit) {
        return;
      }

      const { referenceTime } = await fetchServerTime();

      const visitEndsIn = differenceInMilliseconds(new Date(visit.validUntil), referenceTime);
      const verifyVisitEndIn = visitEndsIn + 1000; // ensure that the visit has truly ended

      const timeout = setTimeout(checkVisitValidity, verifyVisitEndIn);

      return () => clearTimeout(timeout);
    })();
  }, [checkVisitValidity, visit]);

  useEvent("visibilitychange", () => {
    if (!document.hidden) {
      checkVisitValidity();
    }
  });

  const subscribe = useCallback(
    async (userGroupId: string, subuserGroupId?: string) => {
      setLoading(true);

      try {
        await subscribeToUserGroup(userGroupId, subuserGroupId);
        await actions.refreshSession();

        history.push("/");
        window.location.reload();
      } catch (e) {
        console.log("Error on visiting user group", e);
        setLoading(false);
        setError(e);
      }
    },
    [actions, history]
  );

  const unsubscribe = useCallback(async () => {
    setLoading(true);
    setError(undefined);

    try {
      await unsubscribeFromUserGroup();
      await actions.refreshSession();

      window.location.reload();
    } catch (e) {
      console.log("Cannot unsubscribe. Error:", e);

      setError(e);
    } finally {
      setLoading(false);
    }
  }, [actions]);

  return {
    error,
    loading,
    subscribe,
    unsubscribe
  };
};
