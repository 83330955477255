import React from "react";
import { RedirectProps, useLocation, Redirect as RouterRedirect } from "react-router";

export const Redirect = ({ to, ...props }: RedirectProps) => {
  const suppliedState = typeof to === "object" ? to.state : {};
  const toObject = typeof to === "object" ? to : { pathname: to };

  const location = useLocation();

  const toWithState = {
    ...toObject,
    state: {
      ...(location.state as object),
      ...(suppliedState as object)
    }
  };

  return <RouterRedirect {...props} to={toWithState} />;
};
