import React, { useCallback, useMemo } from "react";
import { CacheableFn } from "./create-cacheable-fn";

export type CachedValue<T = any> = {
  value: T;
  expiresAt: number;
};

type Cache = Map<CacheableFn<any>, CachedValue>;
type Subscription = (key: CacheableFn<any>, newValue: CachedValue | undefined) => void;

export const GlobalCacheContext = React.createContext<{
  getValue: (key: CacheableFn<any>) => CachedValue | undefined;
  setValue: (key: CacheableFn<any>, value: CachedValue) => void;
  subscribe: (fn: Subscription) => void;
  unsubscribe: (fn: Subscription) => void;
  deleteValue: (key: CacheableFn<any>) => void;
  clearCache: () => void;
}>(undefined as any);

let subscribers: Subscription[] = [];

export const GlobalCacheProvider: React.FC = props => {
  const cache: Cache = useMemo(() => {
    return new Map<any, CachedValue>();
  }, []);

  const clearCache = useCallback(() => {
    const keys = Array.from(cache.keys());
    cache.clear();
    keys.forEach(k => subscribers.forEach(s => s(k, undefined)));
  }, [cache]);

  const deleteValue = useCallback(
    (key: CacheableFn<any>) => {
      cache.delete(key);
      subscribers.forEach(s => s(key, undefined));
    },
    [cache]
  );

  const getValue = useCallback(
    (key: CacheableFn<any>) => {
      return cache.get(key);
    },
    [cache]
  );

  const setValue = useCallback(
    (key: CacheableFn<any>, value: CachedValue) => {
      const current = cache.get(key);

      if (!current || current.expiresAt < value.expiresAt) {
        cache.set(key, value);
        subscribers.forEach(s => s(key, value));
      }
    },
    [cache]
  );

  const subscribe = useCallback((fn: Subscription) => {
    const exists = subscribers.includes(fn);

    if (!exists) {
      subscribers.push(fn);
    }
  }, []);

  const unsubscribe = useCallback((fn: Subscription) => {
    subscribers = subscribers.filter(sub => sub !== fn);
  }, []);

  const value = useMemo(
    () => ({
      getValue,
      setValue,
      unsubscribe,
      subscribe,
      deleteValue,
      clearCache
    }),
    [clearCache, deleteValue, getValue, setValue, subscribe, unsubscribe]
  );

  return <GlobalCacheContext.Provider value={value}>{props.children}</GlobalCacheContext.Provider>;
};
