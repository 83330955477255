import React, { FunctionComponent } from "react";
import config from "../../../config";

export const AppVersion: FunctionComponent<{
  className?: string;
}> = props => {
  return (
    <p className={props.className}>
      v{config.app.version}
      {!config.app.env.match(/prod/gi) && `-${config.app.env}`}
    </p>
  );
};
