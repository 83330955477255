import { EnvOpts } from ".";

export const config: EnvOpts = {
  useTestingToken: false,
  testingToken: "",
  userPoolAskiWebClientId: "3nd5b5sj3ra7p6pemn04edhsq",
  cognito: {
    region: "eu-west-1",
    userPoolId: "eu-west-1_KiECS2f7E",
    userPoolWebClientId: "5li8vssncmdkue7pp59negfhoh",
    userIdentityProvider: "asiakas360-prod-vr-ad-users",
    adminIdentityProvider: "asiakas360-prod-vr-ad-admins",
    domain: "asiakas360-prod.auth.eu-west-1.amazoncognito.com"
  },
  api: {
    url: "/api"
  }
};
