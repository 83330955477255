import React, { FunctionComponent } from "react";

type CustomerControlProps = {
  userInformation?: API.IUserInformation;
};

export const hasCustomers = (systemId: "rail" | "road", userInformation?: API.IUserInformation) => {
  return userInformation?.customers.some(c => c.systemId === systemId);
};

export const RoadOnly: FunctionComponent<CustomerControlProps> = props => {
  if (hasCustomers("road", props.userInformation)) {
    return <>{props.children}</>;
  }

  return null;
};

export const RailOnly: FunctionComponent<CustomerControlProps> = props => {
  if (hasCustomers("rail", props.userInformation)) {
    return <>{props.children}</>;
  }

  return null;
};
