import React, { FunctionComponent, useState, useCallback } from "react";
import {
  RouteProps as BaseRouteProps,
  Route as BaseRoute,
  RouteComponentProps as BaseRouteComponentProps
} from "react-router-dom";
import { AuthenticationProps, withAuthentication } from "../services/authentication/authentication.provider";
import { AUTH_STATE } from "../services/authentication/authentication.types";
import { ErrorPage } from "../ui/Error/error-page";
import { Loader } from "../ui/Loader/loader";
import { ErrorData, ErrorProps } from "../utils/error";
import { Redirect } from "./redirect";
import { ForceRefreshModal } from "../ui/Modal/force-refresh-modal";

export type NavigationStyle = "MAIN" | "BACK_BURGER" | "ORDER";

export type RouteComponentProps<T = {}, State = any> = BaseRouteComponentProps<T, {}, State> & {
  navigationStyle: NavigationStyle;
};

export type ProtectedRouteComponentProps<T = {}, State = any> = RouteComponentProps<T, State> &
  AuthenticationProps &
  ErrorProps;

const ProtectedRouteBase: FunctionComponent<
  AuthenticationProps &
    Omit<BaseRouteProps, "component"> & { navigationStyle: NavigationStyle } & {
      component: React.ComponentType<ProtectedRouteComponentProps<any>>;
    }
> = ({ component: Page, ...props }) => {
  const [error, setError] = useState<ErrorData | undefined>(undefined);

  const setErrorStatus = useCallback(
    ({ statusCode, error, ...rest }: ErrorData) => {
      if (!statusCode && !error) {
        console.log("Missing error data");

        return;
      }

      setError({
        statusCode,
        error
      });
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <BaseRoute
      {...props}
      render={routeProps => {
        switch (props.auth.state) {
          case AUTH_STATE.SIGN_IN:
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: {
                    from: routeProps.location.pathname + routeProps.location.search + routeProps.location.hash
                  }
                }}
              />
            );
          case AUTH_STATE.LOADING:
            return <Loader />;
          default: {
            if (error) {
              return <ErrorPage error={error} />;
            }

            return (
              <>
                <ForceRefreshModal />
                <Page {...routeProps} auth={props.auth} setError={setErrorStatus} navigationStyle={props.navigationStyle} />
              </>
            );
          }
        }
      }}
    />
  );
};

export const ProtectedRoute = withAuthentication(ProtectedRouteBase);

export const Route: FunctionComponent<
  Omit<BaseRouteProps, "component"> & { navigationStyle: NavigationStyle } & {
    component: React.ComponentType<RouteComponentProps<any>>;
  }
> = ({ component: Page, ...props }) => (
  <BaseRoute {...props} render={routeProps => <Page {...routeProps} navigationStyle={props.navigationStyle} />} />
);
