export interface AppOpts {
  app: {
    version: string;
    env: string;
  };
  api: {
    url: string;
  };

  useTestingToken: boolean;
  testingToken?: string;
  userPoolAskiWebClientId: string;
  cognito: {
    region: string;
    userPoolId: string;
    userPoolWebClientId: string;
    userIdentityProvider: string;
    adminIdentityProvider: string;
    domain: string;
  };
}

export type EnvOpts = Omit<AppOpts, "app">;

type GlobalOpts = Pick<AppOpts, "app">;

const config: GlobalOpts = {
  app: {
    version: process.env["REACT_APP_VERSION"] || "",
    env: process.env["REACT_APP_ENV"] || ""
  }
};

const envConfig: AppOpts = require(`./config.${config.app.env}`).config;

const all: AppOpts = {
  ...config,
  ...envConfig
};

export default all;
