import React, { FunctionComponent } from "react";
import { Modal } from "./index";
import { Loader } from "../Loader/loader";
import styles from "./modal.module.scss";

export const LoaderModal: FunctionComponent<{
  isLoading: boolean;
}> = props => (
  <Modal isVisible={props.isLoading}>
    <div className={styles.loaderContainer}>
      <Loader size="medium" />
    </div>
  </Modal>
);
