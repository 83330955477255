import { cachedGet, safe } from "./utils";
import { apiClient } from "./client";

interface ITimeResponse {
  referenceTime: Date;
  currentTime: Date;
}

interface ICachedTime {
  refreshTime: Date;
  diffReferenceTime?: number;
  diffCurrentTime?: number;
}

const cachedTime: ICachedTime = {
  refreshTime: new Date(),
  diffReferenceTime: undefined,
  diffCurrentTime: undefined
};

const maxRefreshDelay = 3600 * 1000; // 1 hour

export async function fetchServerTime(): Promise<ITimeResponse> {
  const refreshDelay = Date.now() - cachedTime.refreshTime.getTime();

  if (
    cachedTime.diffReferenceTime !== undefined &&
    cachedTime.diffCurrentTime !== undefined &&
    refreshDelay < maxRefreshDelay
  ) {
    return {
      referenceTime: new Date(Date.now() + cachedTime.diffReferenceTime),
      currentTime: new Date(Date.now() + cachedTime.diffCurrentTime)
    };
  }

  const fetchStartTime = Date.now();

  const response = await cachedGet<API.IServerTime>(safe`/server/time`);

  const fetchEndTime = Date.now();
  const referenceTime = new Date(response.data.referenceTime).getTime();
  const currentTime = new Date(response.data.currentTime).getTime();

  cachedTime.refreshTime = new Date(fetchEndTime);
  cachedTime.diffReferenceTime = referenceTime - (fetchStartTime + fetchEndTime) / 2;
  cachedTime.diffCurrentTime = currentTime - (fetchStartTime + fetchEndTime) / 2;

  return {
    referenceTime: new Date(Date.now() + cachedTime.diffReferenceTime),
    currentTime: new Date(Date.now() + cachedTime.diffCurrentTime)
  };
}

export async function fetchServerTimeDirectly () {  
  const response = await apiClient.get<API.IServerTime>(`/server/time`);

  return {
    referenceTime: new Date(response.data.currentTime),
    currentTime: new Date(response.data.currentTime)
  };
};
