import { EntityType } from "../services/api/combined-types";
import { isDefined } from ".";
import { uniqueStrings } from "./array";

type StationLocationTypes = "departure" | "arrival" | "both";

interface IFavoriteStationInformationWithType extends API.IFavoriteStationInformation {
  stationType: StationLocationTypes;
}

export const serialiseCustomersQs = (customers: API.ICustomer[]): Record<EntityType, string | undefined> => {
  const defaultValue: string[] = [];
  const initialValue = { rail: defaultValue, road: defaultValue };
  const ids = customers.reduce((acc, { systemId, customerId }) => {
    acc[systemId] = [...acc[systemId], customerId];

    return { ...acc };
  }, initialValue);

  const rail = ids["rail"].length ? ids["rail"].join(".") : undefined;
  const road = ids["road"].length ? ids["road"].join(".") : undefined;

  return { rail, road };
};

export const serialiseStationsQs = (
  stations: API.IFavoriteStationInformation[]
): Record<StationLocationTypes, string | undefined> => {
  const stationsWithType: IFavoriteStationInformationWithType[] = stations
    .map(s => {
      if (s.asDepartureStation && s.asArrivalStation) {
        return { ...s, stationType: "both" as StationLocationTypes };
      }

      if (s.asDepartureStation && !s.asArrivalStation) {
        return { ...s, stationType: "departure" as StationLocationTypes };
      }

      if (!s.asDepartureStation && s.asArrivalStation) {
        return { ...s, stationType: "arrival" as StationLocationTypes };
      }

      return undefined;
    })
    .filter(isDefined);

  const defaultValue: string[] = [];
  const initialValue = { departure: defaultValue, arrival: defaultValue, both: defaultValue };
  const uicCodes = stationsWithType.reduce((acc, { stationType, stationUicCode }) => {
    acc[stationType] = [...acc[stationType], stationUicCode];

    return { ...acc };
  }, initialValue);

  const departure = uicCodes["departure"].length ? uicCodes["departure"].join(".") : undefined;
  const arrival = uicCodes["arrival"].length ? uicCodes["arrival"].join(".") : undefined;
  const both = uicCodes["both"].length ? uicCodes["both"].join(".") : undefined;

  return { departure, arrival, both };
};

export const deserialiseCustomersQs = (
  query: Record<EntityType, string | undefined>,
  allCustomers: API.ICustomer[]
): API.ICustomer[] => {
  const railIds = query.rail ? query.rail.split(".") : [];
  const roadIds = query.road ? query.road.split(".") : [];

  const findIds = (ids: string[], entity: EntityType) => {
    return ids.map(id => allCustomers.find(c => c.systemId === entity && c.customerId === id)).filter(isDefined);
  };

  return [...findIds(railIds, "rail"), ...findIds(roadIds, "road")];
};

export const deserialiseStationsQs = (
  query: Record<StationLocationTypes, string | undefined>,
  allStations: API.IStationWagonPresence[]
): API.IFavoriteStationInformation[] => {
  const departureUicCodes = query.departure ? query.departure.split(".") : [];
  const arrivalUicCodes = query.arrival ? query.arrival.split(".") : [];
  const bothUicCodes = query.both ? query.both?.split(".") : [];

  const findUicCodes = (uicCodes: string[], type: StationLocationTypes) => {
    return uicCodes
      .map(uicCode => allStations.find(s => s && s.stationUicCode === uicCode))
      .map(s => {
        if (!s) {
          return undefined;
        }

        return {
          stationName: s.stationName,
          stationUicCode: s.stationUicCode,
          asDepartureStation: Boolean(type === "departure" || type === "both"),
          asArrivalStation: Boolean(type === "arrival" || type === "both")
        };
      })
      .filter(isDefined);
  };

  return [
    ...findUicCodes(uniqueStrings(departureUicCodes), "departure"),
    ...findUicCodes(uniqueStrings(arrivalUicCodes), "arrival"),
    ...findUicCodes(uniqueStrings(bothUicCodes), "both")
  ];
};

export const createQueryString = (values: Record<string, string | undefined>): string => {
  const params = new URLSearchParams();

  for (const [name, value] of Object.entries(values)) {
    if (value) {
      params.set(name, value);
    }
  }

  return params.toString();
};
