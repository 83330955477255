import { AxiosResponse } from "axios";
import { cachedGet, safe, DateOptions } from "./utils";
import { makeCacheable } from "../../../utils/use-cacheable";

export const fetchTrainLocations = async (trainNumber: string): Promise<AxiosResponse<API.ILocations>> =>
  cachedGet(safe`/rail/trains/locations/by-train-id/${trainNumber}`);

export const rawFetchTrain = async (trainNumber?: string, options: DateOptions = {}): Promise<API.ITrain> => {
  const date = options.date && options.date.toISOString().slice(0, 10);

  if (!trainNumber) {
    return Promise.reject();
  }

  return cachedGet<API.ITrain>(safe`/rail/trains/by-train-id/${trainNumber}`, {
    params: { ...options, date: date }
  }).then(r => r.data);
};

export const fetchTrain = makeCacheable(rawFetchTrain, 5 * 60 * 1000);
