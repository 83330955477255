import { AxiosResponse } from "axios";
import { cachedGet, safe } from "./utils";
import { apiClient } from "./client";
import { UserRole } from "../../../pages/admin/addUser";
import { UserWithoutCognitoId } from "../../../ui/AdministrateSubUserGroup";

export const fetchAskiUserGroups = async (): Promise<AxiosResponse<API.IAskiUserGroupResponse>> => {
  return cachedGet(safe`/user-management/aski-user-groups`);
};

export const fetchAskiUserGroupById = async (userGroupId: string): Promise<AxiosResponse<API.IAskiUserGroup>> => {
  return cachedGet(safe`/user-management/aski-user-groups/${userGroupId}`);
};

export const addUsersToAskiUserGroup = async (userGroupId: string, users: API.IAskiUserGroupUser[]): Promise<void> => {
  await apiClient.post(safe`/user-management/aski-user-groups/${userGroupId}/users`, {
    users
  });
};

export const removeUserFromAskiUserGroup = async (userGroupId: string, email: string): Promise<void> => {
  await apiClient.delete(safe`/user-management/aski-user-groups/${userGroupId}/users/${email}`);
};

export const fetchUserGroups = async (): Promise<AxiosResponse<API.IUserGroupResponse>> => {
  return cachedGet(safe`/user-management/user-groups`);
};

export const getCustomers = async (): Promise<AxiosResponse<API.ICustomerResponse>> => {
  return cachedGet(safe`/user-management/customers`);
};

export const getPowerBiReports = async (): Promise<AxiosResponse<API.IPowerBiReportResponse>> => {
  return cachedGet(safe`/user-management/power-bi-reports`);
};

export const getUserGroupsByReportLabel = async (reportLabel: string): Promise<AxiosResponse<API.IUserGroupResponse>> => {
  return cachedGet(safe`/user-management/user-groups/by-report/${reportLabel}`);
};

export const addUserGroup = async (userGroup: API.IUserGroupRequest): Promise<void> => {
  await apiClient.post(safe`/user-management/user-groups`, userGroup);
};

export const updatePowerBiReportAccess = async (
  reportLabel: string,
  userGroupIds: API.IReportAccessRequest
): Promise<void> => {
  await apiClient.put(safe`/user-management/user-groups/report-access/${reportLabel}`, userGroupIds);
};

export const updateUserGroup = async (id: string, userGroup: API.IUserGroupRequest): Promise<void> => {
  await apiClient.put(safe`/user-management/user-groups/${id}`, userGroup);
};

export const fetchUserGroupById = async (userGroupId: string): Promise<AxiosResponse<API.IUserGroup>> => {
  return cachedGet(safe`/user-management/user-groups/${userGroupId}`);
};

export const addUsersToUserGroup = async (userGroupId: string, users: UserRole[]): Promise<void> => {
  await apiClient.post(safe`/user-management/user-groups/${userGroupId}/users`, {
    users
  });
};

export const fetchUserByEmail = async (email: string): Promise<AxiosResponse<API.IUser>> => {
  return cachedGet(safe`/user-management/users/by-email/${email}`);
};

export const fetchAskiUserByEmail = async (email: string): Promise<AxiosResponse<API.IAskiUser>> => {
  return cachedGet(safe`/user-management/aski-users/by-email/${email}`);
};

export const removeUserFromUserGroup = async (userGroupId: string, email: string): Promise<void> => {
  await apiClient.delete(safe`/user-management/user-groups/${userGroupId}/users/${email}`);
};

export const removeUserFromSubuserGroup = async (
  userGroupId: string,
  subuserGroupId: string,
  email: string
): Promise<void> => {
  await apiClient.delete(safe`/user-management/user-groups/${userGroupId}/subgroups/${subuserGroupId}/users/${email}`);
};

export const modifyUserInUserGroup = async (
  userGroupId: string,
  email: string,
  role: API.IModifyUserInUserGroupRequest
): Promise<void> => {
  await apiClient.put(safe`/user-management/user-groups/${userGroupId}/users/${email}`, role);
};

export const modifyUserInSubuserGroup = async (
  userGroupId: string,
  subuserGroupId: string,
  email: string,
  modifications: API.IModifyUserInSubuserGroupRequest
): Promise<void> => {
  await apiClient.put(
    safe`/user-management/user-groups/${userGroupId}/subgroups/${subuserGroupId}/users/${email}`,
    modifications
  );
};

export const deleteUser = async (email: string): Promise<void> => {
  await apiClient.delete(safe`/user-management/users/by-email/${email}`);
};

export const resetUserPassword = async (email: string): Promise<void> => {
  await apiClient.post(safe`/user-management/users/${email}/reset-password`, {});
};

export const resetAskiUserPassword = async (email: string): Promise<void> => {
  await apiClient.post(safe`/user-management/users/${email}/reset-aski-password`, {});
};

export const deleteUserGroup = async (userGroupId: string): Promise<void> => {
  await apiClient.delete(safe`/user-management/user-groups/${userGroupId}`);
};

export const deleteSubuserGroup = async (userGroupId: string, subuserGroupId: string): Promise<void> => {
  await apiClient.delete(safe`/user-management/user-groups/${userGroupId}/subgroups/${subuserGroupId}`);
};

export const fetchStations = async (): Promise<AxiosResponse<API.IStationResponse>> => {
  return cachedGet(safe`/user-management/stations`);
};

export const fetchPostalCodes = async (): Promise<AxiosResponse<API.IPostalAreasResponse>> => {
  return cachedGet(safe`/user-management/postal-areas`);
};

const createSubUserGroup = async (
  userGroupId: string,
  subUserGroup: API.ICreateSubuserGroupRequest
): Promise<AxiosResponse<API.ISubuserGroup>> => {
  return await apiClient.post(`/user-management/user-groups/${userGroupId}/subgroups`, subUserGroup);
};

export const addSubUserGroup = async (
  parentUserGroupId: string,
  subUserGroup: API.ICreateSubuserGroupRequest,
  users: UserWithoutCognitoId[]
): Promise<void> => {
  const addedGroup = (await createSubUserGroup(parentUserGroupId, subUserGroup)).data;

  await apiClient.post(`/user-management/user-groups/${parentUserGroupId}/subgroups/${addedGroup.id}/users`, { users });
};

export const modifySubUserGroup = async (
  parentUserGroupId: string,
  subUserGroupId: string,
  subUserGroup: API.ICreateSubuserGroupRequest
): Promise<void> => {
  await apiClient.put(`/user-management/user-groups/${parentUserGroupId}/subgroups/${subUserGroupId}`, subUserGroup);
};

export const modifySubUserGroupUsers = async (
  parentUserGroupId: string,
  subUserGroupId: string,
  users: UserWithoutCognitoId[]
): Promise<void> => {
  await apiClient.post(`/user-management/user-groups/${parentUserGroupId}/subgroups/${subUserGroupId}/users`, { users });
};
