import React, { FunctionComponent } from "react";
import { isDevEnv, isProdOrStagingEnv } from "../../../utils";

export const DevOnly: FunctionComponent = props => {
  if (isDevEnv()) {
    return <>{props.children}</>;
  }

  return null;
};

export const ProdAndStaging: FunctionComponent = props => {
  if (isProdOrStagingEnv()) {
    return <>{props.children}</>;
  }

  return null;
};
