import { AxiosResponse } from "axios";
import { isDefined } from "../../../utils";
import { apiClient } from "./client";
import { safe } from "./utils";
import { withOtm } from "../utils";

export const getRoadOrders = async (
  customerNumber: string,
  from?: Date,
  to?: Date
): Promise<AxiosResponse<API.IPagedRoadCustomerOrders>> => {
  const params = new URLSearchParams();

  params.append("customerNumber", customerNumber);

  if (isDefined(from)) {
    params.append("from", from.toISOString());
  }

  if (isDefined(to)) {
    params.append("to", to.toISOString());
  }

  return apiClient.get(`orders/road?${params.toString()}`, {
    params: withOtm("road")
  });
};

export const getRoadOrder = async (
  customerNumber: string,
  orderNumber: string
): Promise<AxiosResponse<API.IRoadOrderWithStatus>> => {
  return apiClient.get(safe`/orders/road/${customerNumber}/${orderNumber}`, {
    params: withOtm("road")
  });
};

export const modifyRoadOrder = async (
  customerNumber: string,
  orderNumber: string,
  order: any
): Promise<AxiosResponse<API.IRoadOrderResponse>> => {
  return apiClient.put(safe`/orders/road/${customerNumber}/${orderNumber}`, order, { params: withOtm("road") });
};

export const deleteRoadOrder = async (
  customerNumber: string,
  orderNumber: string
): Promise<AxiosResponse<API.IRoadOrderResponse>> => {
  return apiClient.delete(safe`/orders/road/${customerNumber}/${orderNumber}`);
};

export const postRoadOrder = async (order: any): Promise<AxiosResponse<API.IRoadOrderResponse>> => {
  return apiClient.post(safe`/orders/road`, order, { params: withOtm("road") });
};

export const downloadShippingLabels = async (
  customerNumber: string,
  orderNumber: string,
  shippingLabelInformation: API.IRoadShippingLabelInformation
): Promise<void> => {
  const contentType = "application/pdf";
  const anchor = document.createElement("a");
  document.body.appendChild(anchor);

  const { data } = await apiClient.post(
    safe`/orders/road/${customerNumber}/${orderNumber}/shipping-labels`,
    shippingLabelInformation,
    {
      headers: { Accept: contentType },
      responseType: "blob"
    }
  );

  const objectUrl = window.URL.createObjectURL(data);

  anchor.href = objectUrl;
  anchor.download = `${orderNumber}.pdf`;
  anchor.click();

  window.URL.revokeObjectURL(objectUrl);

  document.body.removeChild(anchor);
};

export const downloadWaybills = async (
  customerNumber: string,
  orderNumber: string,
  extraInfo: API.IWaybillInformation
): Promise<void> => {
  const contentType = "application/pdf";
  const anchor = document.createElement("a");
  document.body.appendChild(anchor);

  const { data } = await apiClient.post(
    safe`/orders/road/${customerNumber}/${orderNumber}/waybills`,
    {
      consignee: extraInfo.consignee,
      consignor: extraInfo.consignor
    },
    {
      headers: { Accept: contentType },
      responseType: "blob"
    }
  );

  const objectUrl = window.URL.createObjectURL(data);

  anchor.href = objectUrl;
  anchor.download = `waybill-${orderNumber}-${Date.now()}.pdf`;
  anchor.click();

  window.URL.revokeObjectURL(objectUrl);

  document.body.removeChild(anchor);
};

export const getMovementDocument = async (
  customerNumber: string,
  orderNumber: string
): Promise<AxiosResponse<API.IMovementDocumentStatus>> => {
  return apiClient.get(safe`/orders/road/${customerNumber}/${orderNumber}/movement-document-status`);
};
