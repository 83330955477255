import React, { FunctionComponent } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ProtectedRoute, Route } from "./protected-route";
import { isMainRoute } from "./routes/configuration";
import { orderedRoutes } from "./routes";
import { ErrorPage } from "../ui/Error/error-page";
import { useLocation } from "react-use";

export const Routes: FunctionComponent<{
  children: (content: React.ReactNode) => React.ReactNode;
}> = props => {
  const location = useLocation();
  const hasOriginTrailingDot = Boolean(location?.origin?.match(/\.$/)); // LA-1360

  if (hasOriginTrailingDot && location.origin) {
    window.location.href = location.origin.replace(/\.$/, ""); // LA-1360
  }

  return (
    <BrowserRouter>
      {props.children(
        <Switch>
          {orderedRoutes.filter(isMainRoute).map(route => {
            if (!route.isProtected) {
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  component={route.page}
                  exact={route.isExact}
                  navigationStyle={route.navigationStyle}
                />
              );
            }

            return (
              <ProtectedRoute
                key={route.path}
                path={route.path}
                component={route.page}
                exact={route.isExact}
                navigationStyle={route.navigationStyle}
              />
            );
          })}
          <ErrorPage error={{ statusCode: 404 }} pageNotFound />
        </Switch>
      )}
    </BrowserRouter>
  );
};
