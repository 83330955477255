import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { Authentication } from "../../authentication/authentication";
import config from "../../../config";

const createAuthorizationHeader = (token: string) => ({
  Authorization: `Bearer ${token}`
});

const lowercaseKeys = <T>(obj: Record<string, T>): Record<string, T> => {
  const lowercasedEntries = Object.entries(obj).map(([k, v]) => [k.toLowerCase(), v]);

  return Object.fromEntries(lowercasedEntries);
};

const grabAccessToken = async () => {
  try {
    const session = await Authentication.currentSession();

    return session.getAccessToken().getJwtToken() || "";
  } catch (e) {
    console.error(e);

    return "";
  }
};

const _apiClient = axios.create({ baseURL: config.api.url });

const authInterceptor = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const headers = lowercaseKeys(config.headers) || {};

  if (headers["authorization"]) {
    return config;
  } else {
    return {
      ...config,
      headers: {
        ...headers,
        ...createAuthorizationHeader(await grabAccessToken())
      }
    };
  }
};

const invalidTokenInterceptor = async (err: AxiosError): Promise<never> => {
  if (err.response?.status === 401 && err.response?.data?.type === "invalidToken") {
    await Authentication.signOut();
    window.location.reload();
  }

  throw err;
};

_apiClient.interceptors.request.use(authInterceptor);
_apiClient.interceptors.response.use(res => res, invalidTokenInterceptor);

export const apiClient = _apiClient;
