import { loginTree, loginAskiTree, mainTree, RouteTreeNode } from "./configuration";
import { matchPath } from "react-router";
import { forEachNode, findNode } from "./utils";
import { isDefined } from "../../utils";

export const routeNodesAsList = (node: RouteTreeNode): RouteTreeNode[] => {
  let mutableList: RouteTreeNode[] = [];

  forEachNode(node, current => mutableList.unshift(current));

  return Array.from(new Set(mutableList));
};

export const orderedRoutes = [loginTree, mainTree, loginAskiTree].flatMap(routeNodesAsList);

export const matchRoute = (path: string): RouteTreeNode | undefined => {
  return orderedRoutes.find(r => {
    return matchPath(path, { path: r.path, exact: r.isExact });
  });
};

export const isChildPath = (path: string, maybeChildPath: string): boolean => {
  const route = matchRoute(path);
  const maybeChild = matchRoute(maybeChildPath);

  if (!route || !maybeChild) {
    return false;
  }

  const node = findNode(route, n => {
    const isRoot = route === n;

    return !isRoot && n.path === maybeChild.path;
  });

  return Boolean(node);
};

export const isReplaceableRoute = (currentPath: string, nextPath: string): boolean => {
  const currentRouteGroup = matchRoute(currentPath)?.routeGroup;
  const nextRouteGroup = matchRoute(nextPath)?.routeGroup;

  return isDefined(currentRouteGroup) && isDefined(nextRouteGroup) && currentRouteGroup === nextRouteGroup;
};
