import config from "../config";
import { SiteLocale } from "../services/localisation/types";
import { MaxSelectionsType } from "../ui/CustomerSelection";
import { ReactElement } from "react";

export class UnreachableCaseError extends Error {
  constructor(val: never) {
    super(`Unreachable case: ${val}`);
  }
}

export const waitFor = (ms: number) => new Promise(resolve => window.setTimeout(resolve, ms));

export const removeTrailingSlash = (text: string) => text.replace(/\/+$/, "");

export const relativePath = (basePath: string, to: string) => {
  const path = removeTrailingSlash(basePath);
  const fullPath = to.startsWith("/") ? to : `${path}/${to}`;
  const url = new URL(fullPath, window.location.origin);
  const resultingPath = removeTrailingSlash(decodeURIComponent(url.pathname)) || "/";

  return `${resultingPath}${url.search}${url.hash}`;
};

const mergeObjectList = (obj1: Record<any, any>, obj2: Record<any, any>) => ({
  ...obj1,
  ...obj2
});

export const prefixKeys = (prefix: string, obj?: Record<string, any>) =>
  obj
    ? Object.entries(obj)
        .map(([k, v]) => ({ [`${prefix}${k}`]: v }))
        .reduce(mergeObjectList, {})
    : {};

export const consecutivePairs = <T>(array: T[]): [T | undefined, T][] =>
  array.map((item, index, array) => [array[index - 1], item]);

export const modifyValues = <R, T extends {}>(obj: T, fn: (v: any) => R): Record<keyof T, R> => {
  return Object.entries(obj)
    .map(([k, v]) => ({ [k]: fn(v) }))
    .reduce(mergeObjectList, {}) as any;
};

export type NoUndefinedField<T> = { [P in keyof T]-?: NonNullable<T[P]> };

export const isDefined = <T>(v: T | undefined): v is Exclude<T, undefined> => v !== undefined;

type Falsy = false | 0 | "" | undefined | null;

export const isTruthy = <T>(v: T | Falsy): v is Exclude<T, Falsy> => Boolean(v);

export const isValidRole = (role?: string): role is API.UserGroupRole => role === "admin" || role === "user";

export const removeTrailingZeroes = (num: number) => {
  return num
    .toFixed(3) // round value to specific amount of digits
    .replace(/\.0+$/, "") // remove repeating decimal zeroes (if only zeroes)
    .replace(/(\.\d+?)0+\b/, "$1"); // remove trailing 0's
};

export const formatQuantity = (quantity?: API.IQuantity) => {
  if (!quantity?.value) {
    return "";
  }

  if (typeof quantity.value === "number" && !isNaN(quantity.value)) {
    const unit = quantity.unitCode || "";
    const value = removeTrailingZeroes(quantity.value);

    return value && `${value} ${unit}`;
  }

  return "";
};

export const plural = (count: number, single: string, many: string, none = "") => {
  switch (count) {
    case 0:
      return none;
    case 1:
      return single;
    default:
      return many;
  }
};

type ValueOrMultipleTextFn = <T>(values: T[], multipleValue: ReactElement | string) => T | ReactElement | string | undefined;

export const valueOrMultiple: ValueOrMultipleTextFn = (value, multipleText) =>
  value.length > 1 ? multipleText : first(value);

export const joinTruthy = (text: (string | undefined)[], joinWith = ",") => {
  const withoutFalsy = text.filter(Boolean);

  return withoutFalsy.join(joinWith);
};

export const secondsToDays = (seconds: number) => Math.round(seconds / (60 * 60 * 24));

export const secondsToHours = (seconds: number) => Math.round(seconds / (60 * 60));

export function toArray<T>(optionalElement?: T): T[] {
  if (optionalElement === undefined) {
    return [];
  }

  return [optionalElement];
}

export function first<T>(list: T[]): T | undefined {
  return list[0];
}

export function last<T>(list: T[]): T | undefined {
  return list[list.length - 1];
}

export function groupByFunction<T, K extends string>(
  array: T[],
  fn: (item: T) => K | undefined
): { groups: Record<K, T[] | undefined>; rest: T[] } {
  const groups: Record<string, T[]> = {};
  const rest = [];
  const len = array.length;

  // Intentionally ugly because of optimization.
  for (let mutableIndex = 0; mutableIndex < len; mutableIndex++) {
    const item = array[mutableIndex];
    const key = item ? fn(item) : undefined;

    if (!item) {
      continue;
    }

    if (!key) {
      rest.push(item);
    } else {
      const group = groups[key];

      if (group) {
        group.push(item);
      } else {
        groups[key] = [item];
      }
    }
  }

  return { groups, rest };
}

export function hasItems<T>(items: T[]): items is [T, ...T[]] {
  return items.length > 0;
}

export function hasSeveral<T>(items: T[]): items is [T, T, ...T[]] {
  return items.length > 1;
}

export function hasOne<T>(items: T[]): items is [T] {
  return items.length === 1;
}

export function tupleValueIs<Key, Value, Expected extends Value>(
  guard: (value: Value) => value is Expected
): (tuple: [Key, Value]) => tuple is [Key, Expected] {
  return (tuple): tuple is [Key, Expected] => {
    return guard(tuple[1]);
  };
}

export function hasEntry<T>(tuple: [string, T | undefined]): tuple is [string, T] {
  return isDefined(tuple[1]);
}

export function mapObject<T extends Record<string, any>, V>(
  obj: T,
  fn: (obj: T[keyof T], key: keyof T) => V
): Record<keyof T, V> {
  const result = {} as Record<keyof T, V>;

  // Use for..in because Object.keys and Object.entries has insufficient typings for object keys
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      result[key] = fn(obj[key], key);
    }
  }

  return result;
}

export const isNonCustomerTrackMovement = (trackId?: string, previousTrackId?: string): boolean => {
  if (!trackId || !previousTrackId) {
    return false;
  }

  if (trackId.startsWith("0") && !previousTrackId.startsWith("0")) {
    return true;
  }

  if (!trackId.startsWith("0") && previousTrackId.startsWith("0")) {
    return true;
  }

  return false;
};

export const compareBoolean = (a: boolean, b: boolean) => +b - +a;

type PairWiseFn = <T>(list: T[]) => [T | undefined, T][];

export const pairWithPreviousItem: PairWiseFn = items => items.map((item, index, all) => [all[index - 1], item]);

export function orderByFunction<T, U>(array: T[], fn: (item: T) => U, compareFn: (a: U, b: U) => number) {
  const clone = [...array];
  const mappedCompareFn = (a: T, b: T) => compareFn(fn(a), fn(b));

  clone.sort(mappedCompareFn);

  return clone;
}

export const stringCompare = (a: string, b: string) => a.localeCompare(b);

export const optionalStringCompare = (order: "asc" | "desc", preferTruthy = false) => (a = "", b = "") => {
  if (preferTruthy && (!a || !b)) {
    return +Boolean(b) - +Boolean(a);
  }

  return (order === "desc" ? -1 : 1) * a.localeCompare(b, ["fi"]);
};

export const numberCompare = (order: "asc" | "desc") => (a: number, b: number) => (order === "desc" ? -1 : 1) * (a - b);

export const optionalNumberCompare = (order: "asc" | "desc") => (a?: number, b?: number) => {
  if (!a || !b) {
    return +Boolean(b) - +Boolean(a);
  }

  return (order === "desc" ? -1 : 1) * (a - b);
};

export const matchesAny = <T, U>(item: T[], options: U[], fn: (item: T) => U) => {
  const opt = new Set(options);

  return item.some(i => opt.has(fn(i)));
};

export const minDate = (dates: Date[]) => new Date(Math.min(...dates.map(d => d.getTime())));

export const dateCompare = (order: "asc" | "desc") => (a: Date, b: Date) => {
  return numberCompare(order)(a.getTime(), b.getTime());
};

export const isGlobalUser = (userInformation?: API.IUserInformation): boolean => {
  if (!userInformation) {
    return false;
  }

  return userInformation.isGlobalUser;
};

export const isGlobalAdmin = (userInformation?: API.IUserInformation): boolean => {
  if (!userInformation) {
    return false;
  }

  return userInformation.isGlobalAdmin;
};

// http://ecma-international.org/ecma-262/5.1/#sec-15.9.1.1
// The actual range of times supported by ECMAScript Date objects is slightly smaller:
// exactly –100,000,000 days to 100,000,000 days measured relative to midnight at the beginning of 01 January, 1970 UTC.
// This gives a range of 8,640,000,000,000,000 milliseconds to either side of 01 January, 1970 UTC.
const MAX_DATE_VALUE = 8.64e15;
const MAX_DATE = new Date(MAX_DATE_VALUE);
const MIN_DATE_VALUE = -MAX_DATE_VALUE;
const MIN_DATE = new Date(MIN_DATE_VALUE);

export const optionalDateCompare = (order: "asc" | "desc") => (
  a: Date = order === "asc" ? MAX_DATE : MIN_DATE,
  b: Date = order === "asc" ? MAX_DATE : MIN_DATE
) => dateCompare(order)(a, b);

export * from "./dates";

export * from "./duration";

export const isDevEnv = () => !(config.app.env === "prod" || config.app.env === "staging");

export const isProdOrStagingEnv = () => config.app.env === "prod" || config.app.env === "staging";

type Memo = <T, I>(fn: (v: T) => I) => (v: T) => I;

export const memoize: Memo = fn => {
  const cache = new Map();

  return v => {
    if (cache.get(v)) {
      return cache.get(v);
    }

    const result = fn(v);
    cache.set(v, result);

    return result;
  };
};

export const getReportTitle = <T extends { titleFi: string; titleEn: string; label: string }>(
  report: T,
  locale: SiteLocale
) => {
  if (locale === "fi") {
    return report.titleFi;
  }

  if (locale === "en") {
    return report.titleEn;
  }

  return report.label;
};

export const getCustomerSelectionLimits = (customers: API.ICustomer[]): MaxSelectionsType => {
  const hasRail = customers.some(c => c.systemId === "rail");
  const hasRoad = customers.some(c => c.systemId === "road");

  if (hasRail && hasRoad) {
    return { rail: 20, road: 10 };
  }

  if (hasRail && !hasRoad) {
    return { rail: 20, road: 0 };
  }

  if (!hasRail && hasRoad) {
    return { rail: 0, road: 10 };
  }

  return { rail: 0, road: 0 };
};

export function uniqueBy<T>(array: T[], by: (item: T) => string) {
  const found: Record<string, boolean> = {};
  const result: T[] = [];
  array.forEach(item => {
    const key = by(item);

    if (!found[key]) {
      result.push(item);
    }

    found[key] = true;
  });

  return result;
}

export const isUserGroup = (group: API.IUserGroup | API.ISubuserGroup): group is API.IUserGroup =>
  (group as API.IUserGroup).customers !== undefined && (group as API.IUserGroup).reports !== undefined;

export const isSubUserGroup = (group: API.IUserGroup | API.ISubuserGroup): group is API.ISubuserGroup =>
  (group as API.ISubuserGroup).groupType !== undefined &&
  (group as API.ISubuserGroup).consignees !== undefined &&
  (group as API.ISubuserGroup).stationFilters !== undefined;

export const parseQueryString = (querystring: string) => {
  const params = new URLSearchParams(querystring);

  const paramsObj: Record<string, string[] | string> = {};

  for (const key of Array.from(params.keys())) {
    if (params.getAll(key).length > 1) {
      paramsObj[key] = params.getAll(key);
    } else {
      const param = params.get(key);

      if (param) {
        paramsObj[key] = param;
      }
    }
  }

  return paramsObj;
};

export const uniqueStrings = (array: string[]): string[] => {
  return Array.from(new Set(array));
};
