import React from "react";
import { TransportState } from "../../services/api/selectors";
import { EntityType } from "../../services/api/combined-types";

import {
  TrainIcon,
  TruckIcon,
  FlagIcon,
  RailPickupIcon,
  RoadPickupIcon,
  RailDeliveryIcon,
  RoadDeliveryIcon,
  BookIcon,
  TrackIcon,
  CanceledIcon,
  SvgOverride
} from "./icons";

export interface IconProps {
  className?: string;
  svgOverrides?: SvgOverride;
}

const ORDER_STATE_ICON: Record<"finished" | "rail" | "road", React.ComponentType<IconProps>> = {
  rail: TrainIcon,
  road: TruckIcon,
  finished: FlagIcon
};

export * from "./icons";

export const selectOrderIcon = (type: "rail" | "road", state?: API.OrderStatus | API.TransportStatus) => {
  if (state === "finished" || state === "delivery") {
    return ORDER_STATE_ICON.finished;
  } else if (type === "rail") {
    return ORDER_STATE_ICON.rail;
  } else {
    return ORDER_STATE_ICON.road;
  }
};

export const selectTransportIcon = (
  status: TransportState | "canceled",
  type: EntityType
): React.ComponentType<IconProps> => {
  if (status === "canceled") {
    return CanceledIcon;
  }

  if (status === "pickup") {
    return type === "rail" ? RailPickupIcon : RoadPickupIcon;
  }

  if (status === "delivery") {
    return type === "rail" ? RailDeliveryIcon : RoadDeliveryIcon;
  }

  if (status === "finished") {
    return FlagIcon;
  }

  if (status === "notStarted") {
    return BookIcon;
  }

  if (status === "track") {
    return TrackIcon;
  }

  return type === "rail" ? TrainIcon : TruckIcon;
};
