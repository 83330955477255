import React, { FunctionComponent } from "react";
import classnames from "classnames";
import styles from "./radio-button.module.scss";
import { useRandomId } from "../../utils/use-random-id";

interface InputProps {
  className?: string;
  checked: boolean;
  label: string;
  name: string;
  onClick: () => void;
  disabled?: boolean;
  theme?: "light" | "dark";
  size?: "small" | "intermediate" | "normal" | "large";
  alignment?: "horizontal" | "vertical";
  withMargin?: boolean;
}

export const RadioButton: FunctionComponent<InputProps> = ({
  className,
  checked,
  disabled,
  alignment = "horizontal",
  theme = "light",
  size = "normal",
  withMargin,
  ...props
}) => {
  const id = useRandomId("radio-button");

  return (
    <div
      className={classnames(className, styles.container, styles[theme], styles[size], {
        [styles.withMargin]: withMargin,
        [styles.disabled]: disabled,
        [styles.vertical]: alignment === "vertical"
      })}
      onClick={props.onClick}
    >
      <input type={"radio"} id={id} onChange={props.onClick} checked={checked} {...props} />
      <svg preserveAspectRatio="xMidYMid meet" height="24" width="24" viewBox="0 0 24 24">
        <circle className={styles.outline} cx="12" cy="12" r="10" fill="none" stroke="currentColor" strokeWidth="0.5" />
        {checked && <circle className={styles.dot} cx="12" cy="12" r="7" fill="currentColor" />}
      </svg>
      <label htmlFor={id} className={styles.label}>
        {props.label}
      </label>
    </div>
  );
};
