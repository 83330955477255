import React, { FunctionComponent } from "react";
import { CSSTransition } from "react-transition-group";

import styles from "./appear-slide.module.scss";
import classnames from "classnames";
import { prefixKeys } from "../../../utils";
interface SlideProps {
  isOpen: boolean;
  transitionOnMount?: boolean;
  durationMs: number;
  className?: string;
  autoHeight?: boolean;
  data?: {
    [key: string]: any;
  };
}

const regular = {
  appear: styles.enterDone,
  appearActive: styles.enterDone,
  appearDone: styles.enterDone,
  enter: styles.enter,
  enterActive: styles.enterActive,
  enterDone: styles.enterDone,
  exit: styles.exit,
  exitActive: styles.exitActive,
  exitDone: styles.exitDone
};

export const SlideOnAppear: FunctionComponent<SlideProps> = ({ ...props }) => (
  <CSSTransition appear in={props.isOpen} classNames={regular} timeout={props.durationMs} mountOnEnter>
    <div
      className={classnames(styles.animation, props.className, { [styles.autoHeight]: props.autoHeight })}
      key="slide-in-out"
      style={{
        transitionDuration: `${props.durationMs / 1000}s`
      }}
      {...prefixKeys("data-", props.data)}
    >
      {props.children}
    </div>
  </CSSTransition>
);
