import { useContext, useCallback, useState, useEffect } from "react";
import { useAsyncRetry } from "react-use";
import { AsyncStateRetry } from "react-use/lib/useAsyncRetry";
import { CacheableFn } from "./create-cacheable-fn";
import { GlobalCacheContext, CachedValue } from "./cache";
import { PromiseValue } from "../types";

export { GlobalCacheProvider } from "./cache";

export { makeCacheable } from "./create-cacheable-fn";

type UseCacheableFn = <T>(fn: CacheableFn<T>) => AsyncStateRetry<T>;

export const useCacheable: UseCacheableFn = fn => {
  const { subscribe, unsubscribe, setValue, getValue } = useContext(GlobalCacheContext);

  const [cached, setCached] = useState(getValue(fn));

  useEffect(() => {
    setCached(getValue(fn));
  }, [fn, getValue]);

  const [forceRefetch, setForceRefetch] = useState(false);

  const updateCachedValue = useCallback(
    (key: any, value: CachedValue | undefined) => {
      if (key === fn) {
        setCached(value);
      }
    },
    [fn]
  );

  type AlsoCache = typeof fn.alsoCache;
  type AlsoCacheValue = PromiseValue<ReturnType<typeof fn>>;
  const propagateCache = useCallback((alsoCache: AlsoCache, value: AlsoCacheValue) => {
    if (!alsoCache) {
      return [];
    }

    const result = alsoCache(value);
    let results = [];
    let iterables = [...result];

    while (iterables.length > 0) {
      const next = iterables.shift() || [];
      const [fn, result] = next;

      if (fn && result) {
        results.push([fn, result] as const);
      }

      if (fn?.alsoCache && result) {
        const other = fn.alsoCache(result.value);
        iterables.push(...other);
      }
    }

    return results;
  }, []);

  useEffect(() => {
    subscribe(updateCachedValue);

    return () => {
      unsubscribe(updateCachedValue);
    };
  }, [subscribe, unsubscribe, updateCachedValue]);

  const cachedValue = cached?.value;
  const expiresAt = cached?.expiresAt || 0;

  const { retry: triggerRetry, ...data } = useAsyncRetry(async () => {
    if (forceRefetch || !cachedValue || expiresAt < Date.now()) {
      const value = await fn();
      setForceRefetch(false);
      setValue(fn, { value, expiresAt: Date.now() + fn.cacheDurationMs });

      propagateCache(fn.alsoCache, value).forEach(([fn, cachedValue]) => setValue(fn, cachedValue));

      return value;
    }

    return cachedValue;
  }, [forceRefetch, cachedValue, expiresAt, setValue, fn]);

  const retry = useCallback(() => {
    setForceRefetch(true);
    triggerRetry();
  }, [triggerRetry]);

  return {
    ...data,
    value: cached?.value || data.value,
    retry
  };
};
