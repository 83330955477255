import "react-app-polyfill/stable";
import "core-js/features/promise/all-settled";
import "intersection-observer";
import "scroll-behavior-polyfill";

if (!Intl.PluralRules) {
  require("@formatjs/intl-pluralrules/polyfill");
  require("@formatjs/intl-pluralrules/locale-data/en");
  require("@formatjs/intl-pluralrules/locale-data/fi");
}

if (!(Intl as any).getCanonicalLocales) {
  require("@formatjs/intl-getcanonicallocales/polyfill");
}

if (!(Intl as any).Locale) {
  require("@formatjs/intl-locale/polyfill");
}

if (!(Intl as any).RelativeTimeFormat) {
  require("@formatjs/intl-relativetimeformat/polyfill");
  require("@formatjs/intl-relativetimeformat/locale-data/en");
  require("@formatjs/intl-relativetimeformat/locale-data/fi");
}
