import React, { FunctionComponent, useContext } from "react";
import { Link as RouterLink, LinkProps as LinkPropsLib } from "react-router-dom";

import styles from "./link.module.scss";
import classnames from "classnames";
import { NavigationStateContext } from "../../Navigation";
import { VrTranspointLogo } from "../../Icons";
import { useLocation, useRouteMatch } from "react-router";
import { relativePath } from "../../../utils";
import { isReplaceableRoute, isChildPath } from "../../../router/routes";
import History from "history";

interface InlineLinkProps {
  variant?: "red" | "green" | "white" | "dark";
}

export type LinkProps = Omit<LinkPropsLib, "to"> & {
  to: History.LocationDescriptor<any>;
  className?: string;
};

export const Link: FunctionComponent<LinkProps> = props => {
  const suppliedState = typeof props.to === "object" ? props.to.state : {};
  const toObject = typeof props.to === "object" ? props.to : { pathname: props.to };

  const location = useLocation<any>();
  const match = useRouteMatch();

  const nextPath = relativePath(match.url, toObject.pathname || "");
  const replace = isReplaceableRoute(location.pathname, nextPath);
  const to = {
    ...toObject,
    pathname: nextPath,
    state: {
      fromApplication: replace ? location.state?.fromApplication : true,
      previousRoute: location.pathname,
      isFromChildRoute: replace ? location.state?.isFromChildRoute : isChildPath(nextPath, match.url),
      ...suppliedState
    }
  };

  return <RouterLink {...props} className={props.className || styles.unsetLink} replace={replace} to={to} />;
};

export const InlineLink: FunctionComponent<LinkProps & InlineLinkProps> = ({ className, variant = "green", ...props }) => {
  const path = typeof props.to === "object" ? props.to.pathname || "" : props.to;

  if (path.match(/^\//)) {
    return <Link {...props} className={classnames(styles.link, styles[variant], className)} />;
  } else {
    return (
      <a href={path} className={classnames(styles.link, styles[variant], className)}>
        {props.children}
      </a>
    );
  }
};

export const InlineButton: FunctionComponent<JSX.IntrinsicElements["button"] & InlineLinkProps> = ({
  className,
  variant = "green",
  ...props
}) => <button className={classnames(styles.link, className, styles[variant])} {...props} />;

export const InlineText: FunctionComponent<InlineLinkProps & { className?: string; unstyled?: boolean }> = ({
  className,
  unstyled,
  variant = "green",
  ...props
}) => <div className={classnames(className, styles[variant], { [styles.link]: !unstyled })} {...props} />;

export const LinkToHome: React.FC<{ className?: string }> = props => {
  const { hideNavigation } = useContext(NavigationStateContext);

  return (
    <Link to={`/`} onClick={hideNavigation} className={classnames(styles.logo, props.className)}>
      <VrTranspointLogo />
    </Link>
  );
};
