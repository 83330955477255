import { AxiosResponse } from "axios";
import { isDefined } from "../../../utils";
import { IRoadOrderRow } from "../../../utils/orders";
import { apiClient } from "./client";
import { safe } from "./utils";

const LOCATION_TEMPLATES_PREFIX = "l360-locations";
const ORDER_ROW_TEMPLATES_KEY = "l360-orderRows";

export async function getLocationTemplates(): Promise<API.IRoadLocation[]> {
  const storedLocations: AxiosResponse<API.IRoadLocationListResponse> = await apiClient.get(safe`locations/road`);
  const localPickup = getLocalLocationTemplates("pickup");
  const localDelivery = getLocalLocationTemplates("delivery");

  const localLocations = [...localPickup, ...localDelivery];
  const locations = [...storedLocations.data.locations, ...localLocations];

  return locations.sort((a, b) => a.name.localeCompare(b.name));
}

export async function getLocationTemplatesByCustomer(customerId: string): Promise<API.IRoadLocation[]> {
  const storedLocations: AxiosResponse<API.IRoadLocationListResponse> = await apiClient.get(
    safe`locations/road/${customerId}`
  );
  const localPickup = getLocalLocationTemplates("pickup");
  const localDelivery = getLocalLocationTemplates("delivery");

  const localLocations = [...localPickup, ...localDelivery];

  const locations = [...storedLocations.data.locations, ...localLocations];

  return locations.sort((a, b) => a.name.localeCompare(b.name));
}

function getLocalLocationTemplates(type: API.LocationType): API.IRoadLocation[] {
  const key = `${LOCATION_TEMPLATES_PREFIX}-${type}`;
  const localValues = localStorage.getItem(key);

  if (!localValues) {
    return [];
  }

  try {
    const values: API.IRoadLocation[] = JSON.parse(localValues);

    // Drop id to ensure that there are no collisions with DB ids...
    return values.map(({ id, isTempLocation, assignedFor, ...location }) => ({
      ...location,
      isTempLocation: true,
      type: type
    }));
  } catch (e) {
    return [];
  }
}

interface SaveLocationTemplateOptions {
  type: API.LocationType;
  location: API.IRoadLocation;
}

type DeleteLocationTemplateOptions = SaveLocationTemplateOptions;

export async function saveLocationTemplate({
  type,
  location: { assignedFor, ...location }
}: SaveLocationTemplateOptions): Promise<API.IRoadLocation> {
  const { id, ...saveableLocation } = { ...location, type, isTempLocation: true };

  if (!assignedFor) {
    throw new Error("Unable to save location due to missing assignedFor field");
  }

  const saved: AxiosResponse<API.IRoadLocation> = isDefined(id)
    ? await apiClient.put(safe`locations/road/${assignedFor}/location/${id}`, saveableLocation)
    : await apiClient.post(safe`locations/road/${assignedFor}/location`, saveableLocation);

  deleteLocalLocationTemplate(type, location);

  return saved.data;
}

export async function deleteLocationTemplate({
  type,
  location: { assignedFor, ...location }
}: DeleteLocationTemplateOptions): Promise<void> {
  deleteLocalLocationTemplate(type, location);

  if (location.id && assignedFor) {
    await apiClient.delete(safe`locations/road/${assignedFor}/location/${location.id}`);
  }
}

function deleteLocalLocationTemplate(type: API.LocationType, location: API.IRoadLocation) {
  const key = `${LOCATION_TEMPLATES_PREFIX}-${type}`;
  const storedValues = localStorage.getItem(key);

  if (storedValues) {
    const locations = JSON.parse(storedValues) as API.IRoadLocation[];
    const existingLocationIndex = locations.findIndex(storedLocation => storedLocation.name === location.name);

    if (existingLocationIndex !== -1) {
      locations.splice(existingLocationIndex, 1);
    }

    if (locations.length === 0) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(locations));
    }
  }
}

export const getOrderRowTemplates = (): API.IRoadOrderRow[] => {
  const storedRows = localStorage.getItem(ORDER_ROW_TEMPLATES_KEY);

  return storedRows ? JSON.parse(storedRows).map(mapToOrderRow) : [];
};

export const saveOrderRowTemplate = (orderRow: IRoadOrderRow): void => {
  const template = { ...orderRow, waybillNumber: undefined };

  const storedValues = localStorage.getItem(ORDER_ROW_TEMPLATES_KEY);

  if (storedValues) {
    const orderRows = (JSON.parse(storedValues) as IRoadOrderRow[]).map(mapToOrderRow);

    const existingRowIndex = orderRows.findIndex(
      row =>
        String(row.item.id) === String(template.item.id) && // String() conversion to be sure that types match
        row.orderedAmount === template.orderedAmount &&
        row.orderedUnit === template.orderedUnit
    );

    if (existingRowIndex !== -1) {
      orderRows[existingRowIndex] = template;
    } else {
      orderRows.push(template);
    }

    localStorage.setItem(ORDER_ROW_TEMPLATES_KEY, JSON.stringify(orderRows));
  } else {
    const orderRows = [orderRow];
    localStorage.setItem(ORDER_ROW_TEMPLATES_KEY, JSON.stringify(orderRows));
  }
};

export const deleteOrderRowTemplate = (orderRow: IRoadOrderRow): void => {
  const storedValues = localStorage.getItem(ORDER_ROW_TEMPLATES_KEY);

  if (storedValues) {
    const orderRows = (JSON.parse(storedValues) as IRoadOrderRow[]).map(mapToOrderRow);

    const existingRowIndex = orderRows.findIndex(
      row =>
        String(row.item.id) === String(orderRow.item.id) && // String() conversion to be sure that types match
        row.orderedAmount === orderRow.orderedAmount &&
        row.orderedUnit === orderRow.orderedUnit
    );

    if (existingRowIndex !== -1) {
      orderRows.splice(existingRowIndex, 1);
    }

    if (orderRows.length === 0) {
      localStorage.removeItem(ORDER_ROW_TEMPLATES_KEY);
    } else {
      localStorage.setItem(ORDER_ROW_TEMPLATES_KEY, JSON.stringify(orderRows));
    }
  }
};

function mapToOrderRow(orderRow: IRoadOrderRow): IRoadOrderRow {
  const item = orderRow.item;

  // to make sure types are correct
  return {
    ...orderRow,
    id: orderRow.id ? String(orderRow.id) : undefined,
    item: { ...item, id: String(item.id) }
  };
}
