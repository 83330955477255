export type LocalisedMessages = Record<string, string>;

export const siteLocales = ["en", "fi", "debug"] as const;

export type SiteLocale = typeof siteLocales[number];

export type HasContainers = { usePresentational?: boolean };

export type WithTranslations<Props, Trans extends Record<string, string>> = Props & {
  translations: Trans;
};
