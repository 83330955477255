import Auth, { CognitoUser } from "@aws-amplify/auth";
import config from "../../config";
import { Hub } from "@aws-amplify/core";
import { ClientMetaData } from "@aws-amplify/auth/lib-esm/types";

const mockToken = {
  payload() {
    return {};
  },

  getToken() {
    return config.testingToken;
  },
  getJwtToken() {
    return config.testingToken;
  },
  getExpiration() {
    return 0;
  },
  getIssuedAt() {
    return 0;
  },
  decodePayload() {
    return { id: config.testingToken };
  }
};

const mockSession = {
  getIdToken() {
    return mockToken;
  },
  getAccessToken() {
    return mockToken;
  },
  getRefreshToken() {
    return mockToken;
  },
  isValid() {
    return true;
  }
};

class TestAuth {
  constructor() {
    window.setTimeout(() => {
      Hub.dispatch("auth", { event: "configured" });
    }, 1000);
  }

  configure() {}
  currentSession() {
    return Promise.resolve(mockSession);
  }

  currentUserInfo() {
    return Promise.resolve({});
  }

  currentAuthenticatedUser() {
    return Promise.resolve({});
  }

  signIn(user: any, pw: any) {
    console.warn("NO OP: Sign in doesn't do anything when using testing token");

    return Promise.resolve({});
  }

  completeNewPassword(pw: string) {
    console.warn("NO OP: Password challenge is not possible when using testing token");

    return Promise.resolve();
  }

  signOut() {
    console.warn("NO OP: Signing out is not possible when using testing token");

    return Promise.resolve();
  }

  federatedSignIn() {
    return Promise.resolve();
  }

  forgotPassword() {
    return Promise.resolve();
  }

  forgotPasswordSubmit() {
    return Promise.resolve();
  }

  changePassword(
    user: CognitoUser | any,
    oldPassword: string,
    newPassword: string,
    clientMetadata?: ClientMetaData
  ): Promise<"SUCCESS"> {
    return Promise.resolve("SUCCESS");
  }
}

export const Authentication = !config.useTestingToken ? Auth : new TestAuth();
