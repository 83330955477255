import { EntityType, IOrder, ITransport, ITransportEvent } from "./combined-types";

const isRoad = (obj: IOrder | ITransport | ITransportEvent) => obj.entityType === "road";

const isRail = (obj: IOrder | ITransport | ITransportEvent) => obj.entityType === "rail";

export const isRoadOrder = (obj: IOrder): obj is API.IRoadOrder => isRoad(obj);

export const isRoadTransport = (obj: ITransport): obj is API.IRoadTransport => isRoad(obj);

export const isRoadTransportEvent = (obj: ITransportEvent): obj is API.IRoadTransportEvent => isRoad(obj);

export const isRailOrder = (obj: IOrder): obj is API.IRailOrder => isRail(obj);

export const isRailTransport = (obj: ITransport): obj is API.IRailTransport => isRail(obj);

export const isRailTransportEvent = (obj: ITransportEvent): obj is API.IRailTransportEvent => isRail(obj);

export const findCurrentTransportEvent = <T extends ITransport>(t: T): T["transportEvents"][0] | undefined => {
  return t.transportEvents.find(transportEvent => transportEvent.current);
};

export function withOtm(entity: EntityType) {
  if (entity !== "road") {
    return undefined;
  }

  try {
    const value = JSON.parse(window.localStorage.getItem("useOtm") || "");

    return value ? { otm: true } : undefined;
  } catch (error) {
    return undefined;
  }
}
