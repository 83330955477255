import { FunctionComponent, createContext, useState, useContext } from "react";
import classnames from "classnames";
import { WithTranslations, HasContainers } from "../../../services/localisation/types";
import { addTranslations } from "../../../services/localisation/utils";
import { AlertFilled, CloseIcon, TrainIcon, TruckIcon, InfoCircleFilledBlackIcon, CancelledWhiteIcon } from "../../Icons";
import { PlainButton } from "../../Button/button";
import { hasCustomers } from "../../Controls/CustomerControl/customer-control";
import { withAuthentication } from "../../../services/authentication/authentication.provider";
import styles from "./status-message.module.scss";

const defaultTranslations = {
  statusMessageRail: "",
  statusMessageRoad: "",
  statusMessageRelease: "",
  statusWarningRail: "",
  statusWarningRoad: "",
  statusWarningRelease: "",
  statusInfoRail: "",
  statusInfoRoad: "",
  statusInfoRelease: ""
};

const NO_OP = () => {};
const defaultState: StatusMessageContext = {
  open: true,
  showStatusMessage: NO_OP,
  hideStatusMessage: NO_OP
};

interface StatusMessageContext {
  open: boolean;
  showStatusMessage: () => void;
  hideStatusMessage: () => void;
}

export const StatusMessageStateContext = createContext(defaultState);

export const StatusMessageStateProvider: FunctionComponent = ({ children }) => {
  const [open, setOpen] = useState(defaultState.open);
  const showStatusMessage = () => setOpen(true);
  const hideStatusMessage = () => setOpen(false);

  return (
    <StatusMessageStateContext.Provider
      value={{
        open,
        showStatusMessage,
        hideStatusMessage
      }}
    >
      {children}
    </StatusMessageStateContext.Provider>
  );
};

const UntranslatedStatusMessage: FunctionComponent<
  WithTranslations<{}, typeof defaultTranslations> & HasContainers
> = withAuthentication(({ translations = defaultTranslations, ...props }) => {
  const { open, hideStatusMessage } = useContext(StatusMessageStateContext);

  const showRailMessages = hasCustomers("rail", props.auth.userInformation);
  const showRoadMessages = hasCustomers("road", props.auth.userInformation);

  const railMessage = translations.statusMessageRail !== "undefined" ? translations.statusMessageRail : undefined;
  const roadMessage = translations.statusMessageRoad !== "undefined" ? translations.statusMessageRoad : undefined;
  const releaseMessage = translations.statusMessageRelease !== "undefined" ? translations.statusMessageRelease : undefined;

  const railWarning = translations.statusWarningRail !== "undefined" ? translations.statusWarningRail : undefined;
  const roadWarning = translations.statusWarningRoad !== "undefined" ? translations.statusWarningRoad : undefined;
  const releaseWarning = translations.statusWarningRelease !== "undefined" ? translations.statusWarningRelease : undefined;

  const railInfo = translations.statusInfoRail !== "undefined" ? translations.statusInfoRail : undefined;
  const roadInfo = translations.statusInfoRoad !== "undefined" ? translations.statusInfoRoad : undefined;
  const releaseInfo = translations.statusInfoRelease !== "undefined" ? translations.statusInfoRelease : undefined;

  const uniquifyStatusMessages = (
    releaseMessage?: string,
    railMessage?: string,
    roadMessage?: string,
    releaseInfo?: string,
    railInfo?: string,
    roadInfo?: string,
    releaseWarning?: string,
    railWarning?: string,
    roadWarning?: string
  ): { message: string; type: string }[] => {
    const messages: { message: string; type: string }[] = [];

    if (releaseMessage) {
      messages.push({ message: releaseMessage, type: "message" });
    }

    if (railMessage && showRailMessages) {
      messages.push({ message: railMessage, type: "message" });
    }

    if (roadMessage && showRoadMessages) {
      messages.push({ message: roadMessage, type: "message" });
    }

    if (railWarning && showRailMessages) {
      messages.push({ message: railWarning, type: "warning" });
    }

    if (roadWarning && showRoadMessages) {
      messages.push({ message: roadWarning, type: "warning" });
    }

    if (releaseWarning) {
      messages.push({ message: releaseWarning, type: "warning" });
    }

    if (railInfo && showRailMessages) {
      messages.push({ message: railInfo, type: "infoRail" });
    }

    if (roadInfo && showRoadMessages) {
      messages.push({ message: roadInfo, type: "infoRoad" });
    }

    if (releaseInfo) {
      messages.push({ message: releaseInfo, type: "info" });
    }

    return messages.filter((elem, index, all) => all.indexOf(elem) === index);
  };

  const statusMessages = uniquifyStatusMessages(
    releaseMessage,
    railMessage,
    roadMessage,
    releaseInfo,
    railInfo,
    roadInfo,
    releaseWarning,
    railWarning,
    roadWarning
  );

  // const multipleMessages = statusMessages.length > 1;

  return (
    <>
      {statusMessages.length > 0 && open && props.auth.userInformation && (
        <div className={styles.statusMessageContainer}>
          {statusMessages.map(({ message, type }, index) => (
            <div key={index} className={classnames(styles.messageContainer, {
              [styles.warning]: type === 'warning',
              [styles.message]: type === 'message'
            })}>
              <div className={classnames(styles.icon)}>
                {type === "message" ? <AlertFilled/> : null}
                {type === "warning" ? <CancelledWhiteIcon/> : null}
                {type === "infoRail" ? <TrainIcon/> : null}
                {type === "infoRoad" ? <TruckIcon/> : null}
                {type === "info" ? <InfoCircleFilledBlackIcon/> : null}
              </div>
              <div className={styles.statusMessage}>
                <p className={styles.singleStatusMessage}>
                  {message}
                </p>
              </div>
              <div className={classnames(styles.close)}>
                <PlainButton className={styles.close} onClick={() => hideStatusMessage()}>
                  <CloseIcon />
                </PlainButton>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
});

export const StatusMessage = addTranslations(UntranslatedStatusMessage, {
  statusMessageRail: { id: "main.statusMessageRail" },
  statusMessageRoad: { id: "main.statusMessageRoad" },
  statusMessageRelease: { id: "main.statusMessageRelease" },
  statusWarningRail: { id: "main.statusWarningRail" },
  statusWarningRoad: { id: "main.statusWarningRoad" },
  statusWarningRelease: { id: "main.statusWarningRelease" },
  statusInfoRail: { id: "main.statusInfoRail" },
  statusInfoRoad: { id: "main.statusInfoRoad" },
  statusInfoRelease: { id: "main.statusInfoRelease" }
});
