import "./polyfills";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import config from "./config";
import * as serviceWorker from "./serviceWorker";
import { Authentication } from "./services/authentication/authentication";

declare global {
  interface Window {
    IS_TOUCHING?: boolean;
  }
}

const isAski: Boolean = window.location.pathname.startsWith("/asiakirjat");

const oauth = {
  domain: config.cognito.domain,
  scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
  redirectSignIn: `${window.location.origin}/federated-signin`,
  redirectSignOut: `${window.location.origin}/login`,
  responseType: "code"
};

const oauthAski = {
  domain: config.cognito.domain,
  scope: ["email", "openid"],
  redirectSignIn: "https://test-asiakirjat.vr.fi/asiakirjat/jsp/login_js_enabled.jsp",
  redirectSignOut: `${window.location.origin}/asiakirjat`,
  responseType: "token"
};

window.IS_TOUCHING = false;
window.addEventListener("touchstart", () => (window.IS_TOUCHING = true));
window.addEventListener("touchend", () => (window.IS_TOUCHING = false));
window.addEventListener("touchcancel", () => (window.IS_TOUCHING = false));

ReactDOM.render(<App />, document.getElementById("root"));

Authentication.configure({
  ...config.cognito,
  userPoolWebClientId: isAski ? config.userPoolAskiWebClientId : config.cognito.userPoolWebClientId,
  oauth: isAski ? oauthAski : oauth
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
