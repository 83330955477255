import React, { FunctionComponent } from "react";
import styles from "./alert-box.module.scss";
import { Button } from "../Button/button";
import classnames from "classnames";
import { CloseIcon } from "../Icons";
import { Loader } from "../Loader/loader";

export const AlertBox: FunctionComponent<{
  color: "red" | "green";
  cancelText?: string;
  continueText: string;
  loading?: boolean;
  onCancelPress?: () => void;
  onContinuePress: () => void;
  title: string;
}> = props => (
  <div className={classnames(styles.container, styles[props.color])}>
    {!props.loading && props.onCancelPress ? (
      <button className={styles.closeButton} onClick={props.onCancelPress}>
        <CloseIcon />
      </button>
    ) : (
      <div className={styles.placeholderForClose} />
    )}

    <h3 className={styles.title}>{props.title}</h3>
    {props.loading ? (
      <Loader size={"small"} color={"white"} />
    ) : (
      <Button
        className={styles.button}
        color="white"
        height="small-height"
        width="large-width"
        onClick={props.onContinuePress}
      >
        {props.continueText}
      </Button>
    )}
    {!props.loading && props.cancelText ? (
      <button className={styles.cancelText} onClick={props.onCancelPress}>
        {props.cancelText}
      </button>
    ) : (
      <div className={styles.placeholderForCancelText} />
    )}
  </div>
);
