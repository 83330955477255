import React, { useState, useCallback, useEffect } from "react";
import { CognitoUser } from "@aws-amplify/auth";
import { Authentication } from "../../services/authentication/authentication";
import { useEvent } from "react-use";
import { Modal } from ".";
import { AlertBox } from "../AlertBox";
import { WithTranslations } from "../../services/localisation/types";
import { addTranslations } from "../../services/localisation/utils";
import { useIsMounted } from "../../utils/use-is-mounted";

const defaultTranslations = {
  title: "Your user has been logged out",
  button: "Please refresh"
};

const UntranslatedForceRefreshModal: React.FC<WithTranslations<{}, typeof defaultTranslations>> = ({
  translations = defaultTranslations,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState<string>();
  const [inactiveTab, setInactiveTab] = useState(document.hidden);
  const isMounted = useIsMounted();

  const poll = useCallback(async () => {
    try {
      if (inactiveTab) {
        return;
      }

      const lastAuthUser: CognitoUser = await Authentication.currentAuthenticatedUser();
      const id = lastAuthUser.getUsername();

      if (isMounted.current && currentUser && currentUser !== id) {
        setOpen(true);
      }

      if (isMounted.current && currentUser && currentUser === id) {
        setOpen(false);
      }

      if (isMounted.current && !currentUser) {
        setCurrentUser(id);
      }
    } catch (e) {
      if (isMounted.current && currentUser) {
        setOpen(true);
      }
    }
  }, [currentUser, inactiveTab, isMounted]);

  useEffect(() => {
    poll();
    const interval = setInterval(poll, 5 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [inactiveTab, poll]);

  useEvent("visibilitychange", () => {
    setInactiveTab(document.hidden);
  });

  return (
    <Modal isVisible={open}>
      <AlertBox
        color="red"
        continueText={translations.button}
        title={translations.title}
        onContinuePress={() => {
          setOpen(false);
          window.location.reload();
        }}
        loading={false}
      />
    </Modal>
  );
};

export const ForceRefreshModal = addTranslations(UntranslatedForceRefreshModal, {
  title: { id: "forceRefreshModal.title" },
  button: { id: "forceRefreshModal.button" }
});
