import React, { FunctionComponent, useState, createContext } from "react";
import { SiteLocale, LocalisedMessages, siteLocales } from "./types";
import { IntlProvider, IntlShape, injectIntl } from "react-intl";

type ContextValue = {
  locale: SiteLocale;
  messages: LocalisedMessages;
  setLocale: (locale: SiteLocale) => void;
};

export const LocaleContext = createContext({} as ContextValue);

export const IntlContext = createContext({} as IntlShape);

const RawIntlProvider: React.FC<{ intl: IntlShape }> = ({ intl, children }) => (
  <IntlContext.Provider value={intl}>{children}</IntlContext.Provider>
);
const InjectedIntlProvider = injectIntl(RawIntlProvider);
const LOCALE_STORAGE_KEY = "asiakas360-locale";
const messages = (window as any).___LOCALISATIONS___;

const isSiteLocale = (locale?: string): locale is SiteLocale =>
  Boolean(locale && siteLocales.includes(locale as SiteLocale));

export const LocaleProvider: FunctionComponent = props => {
  const storedLocale = window.localStorage.getItem(LOCALE_STORAGE_KEY) || undefined;
  const persistedLocale = isSiteLocale(storedLocale) ? storedLocale : undefined;

  const shouldHaveFinnish =
    window.navigator.language.toLowerCase().includes("fi") ||
    window.navigator.language.toLowerCase().includes("sv") ||
    !window.navigator.language;

  const browserLocale = shouldHaveFinnish ? "fi" : "en";

  const locale = persistedLocale || browserLocale;
  const [localisation, setLocalisation] = useState<{
    locale: SiteLocale;
    messages: LocalisedMessages;
  }>({
    locale: locale,
    messages: messages[locale] || {}
  });

  const setLocale = (locale: SiteLocale) => {
    window.localStorage.setItem(LOCALE_STORAGE_KEY, locale);
    setLocalisation({ locale, messages: messages[locale] });
  };

  return (
    <LocaleContext.Provider
      value={{
        ...localisation,
        setLocale
      }}
    >
      <IntlProvider locale={localisation.locale === "debug" ? "en" : localisation.locale} messages={localisation.messages}>
        <InjectedIntlProvider children={props.children} />
      </IntlProvider>
    </LocaleContext.Provider>
  );
};
