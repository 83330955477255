import React from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import { matchRoute } from "./routes";

export type BackProps = {
  canBackNavigate: boolean;
  navigateBack: () => void;
  returnPath: string;
};

type WithCanBackNavigateFn = <T>(
  Component: React.ComponentType<T & BackProps>
) => React.FunctionComponent<Exclude<T, BackProps>>;

const populatePath = (path: string, params: Record<string, string>) => {
  return path
    .split("/")
    .map(s => {
      const match = s.match(/:([A-z]+)/);

      const param = match && match[1] ? params[match[1]] : undefined;

      if (param) {
        return s.replace(/:.+/, param);
      }

      return s;
    })
    .join("/");
};

export const withCanBackNavigate: WithCanBackNavigateFn = OriginalComponent => props => {
  const history = useHistory();
  const { state } = useLocation<any>();
  const match = useRouteMatch();

  const isFromApplication = Boolean(state && state.fromApplication);
  const route = matchRoute(match.url);
  const parentPath = populatePath(route?.parent?.path || "/", match.params);
  const previousRoute = state?.previousRoute;

  return (
    <OriginalComponent
      {...props}
      canBackNavigate={previousRoute ? true : isFromApplication}
      navigateBack={() => {
        const location = window.location.href;

        if (previousRoute === "/shunting") {
          history.push("/shunting")
        } else if (!state?.isFromBackButton) {
          history.goBack();
         } else {
          history.replace(parentPath);
        }

        window.setTimeout(() => {
          // Back navigation failed. Perhaps there are no further entries in history?
          if (window.location.href === location) {
            console.log("Back navigatio not possible");
            history.replace(parentPath);
          }
        }, 100);
      }}
      returnPath={parentPath}
    />
  );
};
