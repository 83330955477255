import React, { FunctionComponent, useState } from "react";
import { HasContainers, WithTranslations } from "../../services/localisation/types";
import { ErrorData } from "../../utils/error";
import styles from "./error.module.scss";
import { addTranslations } from "../../services/localisation/utils";
import classnames from "classnames";
import { InlineButton } from "../Atoms/Link";

const defaultTranslations = {
  clickHereForMoreInfo: "Click here to see more information",
  errorCode: "Error code"
};

const UntranslatedMoreInfo: FunctionComponent<
  WithTranslations<
    {
      className?: string;
      error: ErrorData;
    },
    typeof defaultTranslations
  > &
    HasContainers
> = ({ translations = defaultTranslations, error, className }) => {
  const [isInfoVisible, setInfoVisibility] = useState(false);
  const isInfoAvailable = error.statusCode || error.error || error.error;

  return (
    <>
      {isInfoAvailable && !isInfoVisible && (
        <InlineButton onClick={() => !isInfoVisible && setInfoVisibility(true)} className={styles.inline}>
          {translations.clickHereForMoreInfo}
        </InlineButton>
      )}
      {isInfoVisible && (
        <div className={classnames(styles.errorDetails, className)}>
          {error.statusCode && <p>{`${translations.errorCode}: ${error.statusCode}`}</p>}
          {error.error && <pre className={styles.errorMessage}>{JSON.stringify(error.error, null, 2)}</pre>}
        </div>
      )}
    </>
  );
};

export const MoreInfo = addTranslations(UntranslatedMoreInfo, {
  clickHereForMoreInfo: { id: "error.showMoreInfo" },
  errorCode: { id: "error.errorCode" }
});
