export interface FullDuration {
  weeks: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

type DurationWithDays = Omit<FullDuration, "weeks">;
type DurationWithHours = Omit<DurationWithDays, "days">;
type DurationWithMinutes = Omit<DurationWithHours, "hours">;
type DurationWithSeconds = Omit<DurationWithMinutes, "minutes">;

export type Duration = FullDuration | DurationWithDays | DurationWithHours | DurationWithMinutes | DurationWithSeconds;

type PickDuration<MaxUnit extends TimeUnit> = MaxUnit extends "weeks"
  ? FullDuration
  : MaxUnit extends "days"
  ? DurationWithDays
  : MaxUnit extends "hours"
  ? DurationWithHours
  : MaxUnit extends "minutes"
  ? DurationWithMinutes
  : MaxUnit extends "seconds"
  ? DurationWithSeconds
  : never;

const SECONDS_IN_MINUTE = 60;
const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * 60;
const SECONDS_IN_DAY = SECONDS_IN_HOUR * 24;
const SECONDS_IN_WEEK = SECONDS_IN_DAY * 7;

export type TimeUnit = "weeks" | "days" | "hours" | "minutes" | "seconds";

export const TIME_ORDER: Record<TimeUnit, number> = {
  weeks: 4,
  days: 3,
  hours: 2,
  minutes: 1,
  seconds: 0
};

export const isInRange = (min: TimeUnit, max: TimeUnit): boolean => {
  return TIME_ORDER[max] >= TIME_ORDER[min];
};

const calculateRemainingSeconds = (current: number, remainder: number, maxUnit: TimeUnit, currentUnit: TimeUnit): number => {
  return isInRange(currentUnit, maxUnit) ? current % remainder : current;
};

export const hasWeeks = (d: Duration): d is FullDuration => (d as FullDuration).weeks !== undefined;

export const hasDays = (d: Duration): d is DurationWithDays => (d as DurationWithDays).days !== undefined;

export const hasHours = (d: Duration): d is DurationWithHours => (d as DurationWithHours).hours !== undefined;

export const hasMinutes = (d: Duration): d is DurationWithMinutes => (d as DurationWithMinutes).minutes !== undefined;

export const hasSeconds = (d: Duration): d is DurationWithSeconds => (d as DurationWithSeconds).seconds !== undefined;

export function getDuration<T extends TimeUnit = "weeks">(inSeconds: number, maxUnit?: T): PickDuration<T> {
  const unit: TimeUnit = maxUnit || "weeks";
  const sign = inSeconds < 0 ? -1 : 1;
  const absSeconds = Math.abs(inSeconds);
  const weeks = sign * Math.floor(absSeconds / SECONDS_IN_WEEK) || 0;
  let remainingSeconds = calculateRemainingSeconds(absSeconds, SECONDS_IN_WEEK, unit, "weeks");

  const days = sign * Math.floor(remainingSeconds / SECONDS_IN_DAY) || 0;
  remainingSeconds = calculateRemainingSeconds(absSeconds, SECONDS_IN_DAY, unit, "days");

  const hours = sign * Math.floor(remainingSeconds / SECONDS_IN_HOUR) || 0;
  remainingSeconds = calculateRemainingSeconds(absSeconds, SECONDS_IN_HOUR, unit, "hours");

  const minutes = sign * Math.floor(remainingSeconds / SECONDS_IN_MINUTE) || 0;

  remainingSeconds = calculateRemainingSeconds(absSeconds, SECONDS_IN_MINUTE, unit, "minutes");
  const seconds = sign * remainingSeconds || 0;

  return {
    weeks: isInRange("weeks", unit) ? weeks : undefined,
    days: isInRange("days", unit) ? days : undefined,
    hours: isInRange("hours", unit) ? hours : undefined,
    minutes: isInRange("minutes", unit) ? minutes : undefined,
    seconds: isInRange("seconds", unit) ? seconds : undefined
  } as PickDuration<T>;
}
