import { getYear, eachWeekendOfInterval, isBefore, isValid, parse, subDays, startOfMonth, subMonths } from "date-fns";
import { format, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import fi from "date-fns/locale/fi";
import { isDefined } from ".";
import { IDateRange } from "./orders";

const timezone = "Europe/Helsinki";

export const formatShortDate = (d: Date) => {
  return format(utcToZonedTime(d, timezone), "d.M.");
};

export const formatLongDate = (d: string | Date) => {
  return format(utcToZonedTime(d, timezone), "d.M.yyyy");
};

export const formatFullDate = (d: string | Date | undefined) => {
  return isDefined(d) ? format(utcToZonedTime(d, timezone), "dd.MM.yyyy") : "";
};

export const formatTime = (d: Date) => {
  return format(utcToZonedTime(d, timezone), "HH:mm");
};

export const formatDateAndTime = (d?: string | Date, hideTimeOnMidnight = true, showYear = true) => {
  if (!d) {
    return;
  }

  const dayFormat = showYear ? "dd.MM.yyyy" : "dd.MM.";

  if (isMidnight(d) && hideTimeOnMidnight) {
    return format(utcToZonedTime(d, timezone), dayFormat);
  } else {
    return format(utcToZonedTime(d, timezone), `${dayFormat} HH:mm`);
  }
};

export const hasWeekend = (date1?: Date, date2?: Date) => {
  if (!date1 || !date2) {
    return false;
  }

  const start = utcToZonedTime(date1, timezone);
  const end = utcToZonedTime(date2, timezone);

  const startBeforeEnd = isBefore(start, end);

  const weekendDays = startBeforeEnd
    ? eachWeekendOfInterval({ start, end })
    : eachWeekendOfInterval({ start: end, end: start });

  return weekendDays.length > 0;
};

export const isMidnight = (d: string | Date) => {
  const time = format(utcToZonedTime(d, timezone), "HH:mm:ss");

  return time === "00:00:00" || time === "23:59:59";
};

export const formatMonthAndYear = (d: Date, locale: "fi" | "en" | "debug") => {
  const monthAndYear = format(d, "LLLL yyyy", locale ? { locale: fi } : undefined);

  return monthAndYear.charAt(0).toUpperCase() + monthAndYear.slice(1);
};

export const isSameDay = (first?: Date, second?: Date) => {
  if (!first || !second) {
    return false;
  }

  return formatShortDate(first) === formatShortDate(second);
};

export const formatDateRange = (start?: Date, end?: Date, options: { printDate?: boolean } = {}): string => {
  const printDates = options.printDate;

  if (!start || !end) {
    if (end) {
      return `– ${formatTime(end)}`;
    } else if (start) {
      return `${formatTime(start)}`;
    } else {
      return "";
    }
  }

  if (!isSameDay(start, end)) {
    return `${formatShortDate(start)} ${formatTime(start)} – ${formatShortDate(end)} ${formatTime(end)}`;
  } else if (printDates) {
    return `${formatShortDate(start)} ${formatTime(start)} – ${formatTime(end)}`;
  } else {
    return `${formatTime(start)} – ${formatTime(end)}`;
  }
};

export const formatOrderDateRange = (
  dateRange: IDateRange,
  labels: { workingDaysOnly: string },
  options: { printTime: boolean } = { printTime: false }
) => {
  let { start, end, includeWeekend } = dateRange;

  if (!start || !end) {
    if (start) {
      end = start;
    } else {
      return "";
    }
  }

  let formattedRange = "";

  if (isSameDay(start, end)) {
    formattedRange += `${formatLongDate(start)}`;
  } else {
    if (getYear(start) === getYear(end)) {
      formattedRange += `${formatShortDate(start)} – ${formatLongDate(end)}`;
    } else {
      formattedRange += `${formatLongDate(start)} – ${formatLongDate(end)}`;
    }

    if (hasWeekend(start, end) && !includeWeekend) {
      formattedRange += ` (${labels.workingDaysOnly})`;
    }
  }

  if (options.printTime) {
    formattedRange += ` ${formatTime(start)} - ${formatTime(end)}`;
  }

  return formattedRange;
};

export const formatTimeRange = (dateRange: IDateRange) => {
  const { start, end } = dateRange;

  if (!start || !end) {
    return "";
  }

  return `${formatTime(start)} - ${formatTime(end)}`;
};

export function asDate(date: undefined): undefined;

export function asDate(date: string): Date;

export function asDate(date?: string): Date | undefined;

export function asDate(date?: string) {
  return date ? new Date(date) : undefined;
}

export function parseFullDate(date: string, referenceDate: Date) {
  return parse(date, "dd.MM.yyyy", referenceDate);
}

export function isOrderDateValid(date: Date, minDate: Date) {
  return isValid(date) && (isSameDay(date, minDate) || isBefore(minDate, date));
}

export function getFirstDaysOfMonth(currentTime: Date, months: number) {
  const dates = [];

  for (let i = 0; i < months; i++) {
    dates.push(startOfMonth(subMonths(currentTime, i)));
  }

  return dates;
}

export function getLocalTimeOnPreviousDay(date: Date, hours: number) {
  const zonedTime = `${format(subDays(date, 1), "yyyy-MM-dd")}T${hours}:00:00`;

  return zonedTimeToUtc(zonedTime, timezone);
}

export function getLocalTimeOnDate(date: Date, hours: number) {
  const zonedTime = `${format(date, "yyyy-MM-dd")}T${hours}:00:00`;

  return zonedTimeToUtc(zonedTime, timezone);
}

export function dayIndex(date: Date): number {
  const millisec = date.getTime();

  return Math.floor(millisec / 86400000);
}

