import { MutableRefObject, useCallback, useEffect } from "react";

export const useCloseModal = (onClose: () => void, ref: MutableRefObject<any>) => {
  const keyboardEventListener = useCallback(
    (e: KeyboardEvent) => {
      if (e.key !== "Escape") {
        return;
      }

      onClose();
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyboardEventListener);

    return () => {
      document.removeEventListener("keydown", keyboardEventListener);
    };
  });
};
