import { RouteTreeNode } from "./configuration";

export const forEachNode = (root: RouteTreeNode, fn: (current: RouteTreeNode) => void) => {
  let mutableList = [root, ...(root.children || [])];
  while (mutableList.length > 0) {
    const current = mutableList.shift();

    if (!current) {
      return;
    }

    mutableList.unshift(...(current.children || []));
    fn(current);
  }
};

export const findNode = (root: RouteTreeNode, predicate: (current: RouteTreeNode) => boolean): RouteTreeNode | undefined => {
  let mutableList = [root, ...(root.children || [])];
  while (mutableList.length > 0) {
    const current = mutableList.shift();

    if (!current) {
      return;
    }

    mutableList.unshift(...(current.children || []));

    const found = predicate(current);

    if (found) {
      return current;
    }
  }
};
