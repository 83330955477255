import { EnvOpts } from ".";

export const config: EnvOpts = {
  useTestingToken: false,
  testingToken: "",
  userPoolAskiWebClientId: "pcl1ick70j1h1t4ia6ttukd8b",
  cognito: {
    region: "eu-west-1",
    userPoolId: "eu-west-1_zeCisCiyS",
    userPoolWebClientId: "1i76j03p0hbd6qkq63c6h9hoch",
    userIdentityProvider: "asiakas360-test-vr-ad-users",
    adminIdentityProvider: "asiakas360-test-vr-ad-admins",
    domain: "asiakas360-test.auth.eu-west-1.amazoncognito.com"
  },
  api: {
    url: "/api"
  }
};
