import { AxiosResponse, AxiosRequestConfig } from "axios";
import { apiClient } from "./client";

export interface StrictAxiosRequestConfig extends AxiosRequestConfig {
  params?: Record<string, string | number | boolean | undefined | null>; // NOSONAR
}

export const cachedGet = <T>(url: string, config?: StrictAxiosRequestConfig): Promise<AxiosResponse<T>> => {
  const params =
    (config &&
      config.params &&
      Object.entries(config.params)
        .map(([k, v]) => `${k}=${v}`)
        .sort()
        .join(",")) ||
    "";

  const cacheKey = url + params;

  return withInflightCache(cacheKey, () => apiClient.get<T>(url, config));
};

export const safe = (strings: TemplateStringsArray, ...params: string[]) => {
  return strings
    .map((str, i) => {
      const param = params[i];

      if (param) {
        return `${str}${encodeURIComponent(param)}`;
      }

      return str;
    })
    .join("");
};

const inflight: Record<string, Promise<any> | undefined> = {};

export async function withInflightCache<T>(key: string, fn: () => Promise<T>): Promise<T> {
  if (inflight[key]) {
    return inflight[key];
  }

  const request = fn();
  inflight[key] = request;

  const clear = () => (inflight[key] = undefined);
  request.then(clear).catch(clear);

  return request;
}

export interface PaginationOptions {
  offset?: number;
  limit?: number;
}

export interface DateOptions {
  date?: Date;
}

// mockAxiosResponse might be useful again later on
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const mockAxiosResponse = <T>(data: T): Promise<AxiosResponse<T>> => {
  return Promise.resolve({
    data: JSON.parse(JSON.stringify(data)),
    status: 200,
    statusText: "OK",
    headers: {},
    config: {},
    request: {}
  });
};
