import { AxiosResponse } from "axios";
import { apiClient } from "./client";
import { safe } from "./utils";

export const fetchUserActivityReport = async (): Promise<AxiosResponse<API.IActiveUsersReport>> => {
  return apiClient.get(safe`/reports/active-users`);
};

export const fetchPowerBiReport = async (customers: API.ICustomer[]): Promise<AxiosResponse<API.IReportResponse>> => {
  return apiClient.post(safe`/power-bi/reports`, { customers });
};
