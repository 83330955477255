import { AxiosResponse } from "axios";
import { withOtm } from "../utils";
import { apiClient } from "./client";
import { safe } from "./utils";

export const fetchRoadContract = async (customerNumber: string): Promise<AxiosResponse<API.IRoadContract>> => {
  return apiClient.get(safe`/contracts/road?customerNumber=${customerNumber}`, {
    params: withOtm("road")
  });
};

export const fetchRailContracts = async (customerNumber: string): Promise<AxiosResponse<API.IRailContracts>> => {
  return apiClient.get(safe`/contracts/rail?customerNumber=${customerNumber}`);
};

export const fetchRailContractProducts = async (
  customerNumber: string,
  contractNumber: string
): Promise<AxiosResponse<API.IRailCargoType[]>> => {
  return apiClient.get(safe`/contracts/rail/${contractNumber}/products?customerNumber=${customerNumber}`);
};

export const fetchRailContractsForWagonTypes = async (
  customerNumber: string
): Promise<AxiosResponse<API.IRailContracts>> => {
  return apiClient.get(safe`/contracts/rail/wagonTypes?customerNumber=${customerNumber}`);
};
