import React, { useState, useCallback, useContext, useEffect } from "react";
import { isDefined } from "../../utils";
type BundleStateContextValue = {
  state: Record<string, boolean>;
  updateValue: (id: string, state: boolean) => void;
  clear: () => void;
};

export const BundleStateContext = React.createContext<BundleStateContextValue>({
  state: {},
  updateValue: () => {},
  clear: () => {}
});

export const BundleStateProvider: React.FC = props => {
  const [value, setValue] = useState<Record<string, boolean>>({});

  const updateValue = useCallback((id: string, state: boolean) => {
    setValue(s => ({ ...s, [id]: state }));
  }, []);

  const clear = useCallback(() => {
    setValue({});
  }, []);

  const output: BundleStateContextValue = {
    state: value,
    updateValue,
    clear
  };

  return <BundleStateContext.Provider value={output}>{props.children}</BundleStateContext.Provider>;
};

export const useSingleBundleState = (id: string, defaultValue: boolean): [boolean, (nextValue?: boolean) => void] => {
  const { state: bundleStates, updateValue } = useContext(BundleStateContext);
  const current = bundleStates[id];
  useEffect(() => {
    if (!isDefined(current)) {
      updateValue(id, defaultValue);
    }
  }, [current, defaultValue, id, updateValue]);

  const isShown: boolean = bundleStates[id] || defaultValue;

  const toggle = useCallback(
    (nextValue?: boolean) => {
      const value = isDefined(nextValue) ? Boolean(nextValue) : !isShown;
      updateValue(id, value);
    },
    [id, isShown, updateValue]
  );

  return [isShown, toggle];
};
