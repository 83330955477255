import React, { ComponentType } from "react";
import { Redirect, RouteComponentProps as BaseRouteComponentProps } from "react-router";

import { EntityType } from "../../services/api/combined-types";
import { routesByName } from "./configuration";
import { createQueryString } from "../../utils/query";
import loadable from "@loadable/component";
import { unsafeErrorToError } from "../../utils/error";
import { ErrorPage } from "../../ui/Error/error-page";
import { LoaderWithMessage } from "../../ui/Loader/LoaderWithMessage";

type ReactAsyncModuleImport = () => Promise<ComponentType<any>>;

export class ErrorBoundary extends React.Component<{}> {
  state: { error: undefined | any } = { error: undefined };

  static getDerivedStateFromError(error: any) {
    console.log(error);

    return { error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.error) {
      return (
        <ErrorPage
          error={{
            error: unsafeErrorToError(this.state.error)?.message || "Something went wrong"
          }}
        />
      );
    }

    return this.props.children;
  }
}

export const asAsync = (importFn: ReactAsyncModuleImport) => {
  const Cmp = loadable(importFn, {
    fallback: <LoaderWithMessage />
  });

  return (props: any) => (
    <ErrorBoundary>
      <Cmp {...props} />
    </ErrorBoundary>
  );
};

export const AsyncLoginPage = asAsync(async () => (await import("../../pages/login/login")).LoginPage);

export const AsyncLoginAskiPage = asAsync(async () => (await import("../../pages/login/loginAski")).LoginAskiPage);

export const AsyncLogoutAskiPage = asAsync(async () => (await import("../../pages/login/logoutAski")).LogoutAskiPage);

export const AsyncFederatedSignInPage = asAsync(
  async () => (await import("../../pages/login/federated-signin")).FederatedSignInPage
);

export const AsyncForgotPassword = asAsync(
  async () => (await import("../../pages/login/forgot-password")).ForgotPasswordPage
);

export const AsyncSetNewPassword = asAsync(
  async () => (await import("../../pages/login/set-new-password")).SetNewPasswordPage
);

export const AsyncChangePasswordPage = asAsync(
  async () => (await import("../../pages/login/change-password")).ChangePasswordPage
);

export const AsyncSearchPage = asAsync(async () => (await import("../../pages/search/search")).SearchPage);

export const AsyncTrackOrderPage = asAsync(
  async () => (await import("../../pages/tracking/order/overview")).OrderOverviewPage
);

export const AsyncViewOrderPage = asAsync(async () => (await import("../../pages/orders/pending-order")).PendingOrderPage);

export const AsyncTransportPage = asAsync(async () => (await import("../../pages/tracking/order/transport")).TransportPage);

export const AsyncUserGroupPage = asAsync(async () => (await import("../../pages/admin/userGroups")).UserGroups);

export const AsyncAskiUserGroupPage = asAsync(async () => (await import("../../pages/admin/askiUserGroups")).AskiUserGroups);

export const AsyncUserGroupAdministrationPage = asAsync(
  async () => (await import("../../pages/admin/administrateUserGroup")).AdministrateUserGroup
);

export const AsyncCreateSubUserGroup = asAsync(
  async () => (await import("../../pages/admin/createSubUserGroup")).CreateSubUserGroup
);

export const AsyncModifySubUserGroup = asAsync(
  async () => (await import("../../pages/admin/modifySubUserGroup")).ModifySubUserGroup
);

export const AsyncAddUserPage = asAsync(async () => (await import("../../pages/admin/addUser")).AddUserPage);

export const AsyncAddAskiUserPage = asAsync(async () => (await import("../../pages/admin/addAskiUser")).AddAskiUserPage);

export const AsyncModifyUserPage = asAsync(async () => (await import("../../pages/admin/modifyUser")).ModifyUserPage);

export const AsyncModifyAskiUserPage = asAsync(
  async () => (await import("../../pages/admin/modifyAskiUser")).ModifyAskiUserPage
);

export const AsyncStationView = asAsync(async () => (await import("../../pages/station")).StationPage);

export const AsyncWagonPage = asAsync(async () => (await import("../../pages/wagon")).WagonPage);

export const AsyncReleaseNotesPage = asAsync(
  async () => (await import("../../pages/options/release-notes")).ReleaseNotesPage
);

export const RootPageRedirect = () => <Redirect to={"/search"} />;

export const RedirectFromTrackingToSearch = (props: BaseRouteComponentProps<{ customerid: string; entity: EntityType }>) => {
  return (
    <Redirect
      to={{
        pathname: routesByName.search.path,
        search: createQueryString({ [props.match.params.entity]: props.match.params.customerid })
      }}
    />
  );
};

export const AsyncTrainView = asAsync(async () => (await import("../../pages/train")).TrainPage);

export const AsyncActivityReport = asAsync(
  async () => (await import("../../pages/admin/user-activity-report")).UserActivityReportPage
);

export const AsyncPowerBiReport = asAsync(async () => (await import("../../pages/power-bi")).ReportPage);

export const AsyncPowerBiReportAccessManagement = asAsync(
  async () => (await import("../../pages/power-bi/access-management/report-access-management")).ReportAccessManagement
);

export const AsyncOrders = asAsync(async () => (await import("../../pages/orders")).OrdersPage);

export const AsyncOrderCreationRoad = asAsync(async () => (await import("../../pages/orders/road-order")).RoadOrderPage);

export const AsyncOrderCreationRail = asAsync(async () => (await import("../../pages/orders/rail-order")).RailOrderPage);

export const AsyncOrderSummary = asAsync(async () => (await import("../../pages/orders/order-summary")).OrderSummaryPage);

export const AsyncBoundWagonsPage = asAsync(async () => (await import("../../pages/bound-wagons")).BoundWagonsPage);

export const AsyncCookiePolicyPage = asAsync(async () => (await import("../../pages/policies/cookies")).CookiePolicyPage);

export const AsyncPrivacyPolicyPage = asAsync(async () => (await import("../../pages/policies/privacy")).PrivacyPolicyPage);

export const AsyncInventoryAndLoadingInstructionsPage = asAsync(
  async () => (await import("../../pages/instructions")).InventoryAndLoadingInstructionsPage
);

export const AsyncGeneralInstructionsPage = asAsync(
  async () => (await import("../../pages/instructions")).GeneralInstructionsPage
);

export const AsyncShuntingPage = asAsync(async () => (await import("../../pages/shunting")).ShuntingPage);

export const AsyncShuntingEditPage = asAsync(
  async () => (await import("../../pages/shunting/shunting-edit")).ShuntingEditPage
);

export const AsyncShuntingOrdersPage = asAsync(
  async () => (await import("../../pages/shunting/sent-orders-list")).ShuntingOrdersPage
);

export const AsyncEmptyWagonShuntingEditPage = asAsync(
  async () => (await import("../../pages/shunting/shunting-empty-wagon-edit")).ShuntingEmptyWagonEditPage
);

export const AsyncContinualShuntingEditPage = asAsync(
  async () => (await import("../../pages/shunting/continual-shunting-edit")).ContinualShuntingEditPage
);
