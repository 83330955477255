import React, { FunctionComponent, useContext } from "react";
import { LocaleContext } from "../services/localisation/locale-provider";
import styles from "./language-selection.module.scss";
import { HasContainers, WithTranslations, SiteLocale } from "../services/localisation/types";
import { addTranslations } from "../services/localisation/utils";
import { RadioButton } from "../ui/RadioButton";
import { DevOnly } from "../ui/Controls/EnvControl/env-control";

const defaultTranslations = {
  suomi: "Suomi",
  english: "English"
};

const UntranslatedLanguageSelection: FunctionComponent<
  WithTranslations<{ onChange: (locale: SiteLocale) => void }, typeof defaultTranslations> & HasContainers
> = ({ translations = defaultTranslations, ...props }) => {
  const { locale, setLocale } = useContext(LocaleContext);

  const onLocaleChange = (newValue: SiteLocale) => {
    if (locale === newValue) {
      return;
    }

    setLocale(newValue);
    props.onChange(newValue);
  };

  return (
    <>
      <div className={styles.container}>
        <RadioButton
          name="language-selection"
          onClick={() => onLocaleChange("fi")}
          label={translations.suomi}
          checked={locale === "fi"}
          withMargin
        />
        <RadioButton
          name="language-selection"
          onClick={() => onLocaleChange("en")}
          label={translations.english}
          checked={locale === "en"}
          withMargin
        />

        <DevOnly>
          <RadioButton
            name="language-selection"
            onClick={() => onLocaleChange("debug")}
            label="Debug mode"
            checked={locale === "debug"}
            withMargin
          />
        </DevOnly>
      </div>
    </>
  );
};

export const LanguageSelection = addTranslations(UntranslatedLanguageSelection, {
  suomi: { id: "language.suomi" },
  english: { id: "language.english" }
});
