import { CognitoUser } from "@aws-amplify/auth";

export enum AUTH_STATE {
  LOADING = "LOADING",
  SIGNED_IN = "SIGNED_IN",
  SIGN_IN = "SIGN_IN",
  NEW_PASSWORD = "NEW_PASSWORD"
}

export type CognitoChallengeName = "SMS_MFA" | "SOFTWARE_TOKEN_MFA" | "NEW_PASSWORD_REQUIRED" | "MFA_SETUP";

export type CognitoUserWithChallenge = CognitoUser & {
  challengeName: CognitoChallengeName;
  challengeParam: {
    requiredAttributes: string[];
    userAttributes: { [key: string]: any };
  };
};

export const isChallengedUser = (user: CognitoUser | CognitoUserWithChallenge): user is CognitoUserWithChallenge =>
  Boolean((user as CognitoUserWithChallenge).challengeName);
