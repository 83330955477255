import { AxiosError } from "axios";

export type ErrorData = { statusCode?: number; error?: string };

export type SetError = ({ statusCode, error }: ErrorData) => void;

export type ErrorProps = {
  setError: SetError;
};

const isAxiosError = (error: Error): error is AxiosError => {
  return Boolean((error as any).isAxiosError);
};

export const getErrorData = (error: any): ErrorData => {
  const safeError = unsafeErrorToError(error);

  if (safeError) {
    if (isAxiosError(safeError)) {
      const code = safeError.code && parseInt(safeError.code, 10);
      const message = safeError.response ? safeError.response.data : safeError.message;

      return {
        statusCode: code && !isNaN(code) ? code : safeError.response?.status,
        error: message
      };
    } else if (safeError.message) {
      return { error: safeError.message };
    }
  }

  return { error: undefined, statusCode: undefined };
};

export const selectError = (error: any, setError: SetError): void => {
  const safeError = unsafeErrorToError(error);

  if (!safeError) {
    return;
  }

  const errorData = getErrorData(safeError);

  if (errorData.error && errorData.statusCode) {
    setError({
      statusCode: errorData.statusCode,
      error: errorData.error
    });
  } else if (errorData.error && !errorData.statusCode) {
    setError({
      error: errorData.error
    });
  } else {
    setError({
      error: "Could not get response. Please check your network connection."
    });
  }
};

export const unsafeErrorToError = (error: any): Error | undefined => {
  if (!error) {
    return undefined;
  }

  if (error instanceof Error) {
    return error;
  }

  if (typeof error === "string") {
    return new Error(error);
  }

  if (typeof error === "object") {
    try {
      return new Error(JSON.stringify(error));
    } catch (stringifyError) {
      console.error("Error while converting unsafeError to Error!", { stringifyError, error });
    }
  }

  try {
    return new Error(`${error}`);
  } catch (e) {
    return new Error("Could not stringify the received error");
  }
};
